import InnovationImg from '../../images/staffwizard/innovation.webp'
import Line from '../../images/staffwizard/line.webp';

const Innovation = () => {
    return (
        <section className='innovation-wrapper' >


                <div className="approach-content">

                    <div className='innovation-text'>
                        <div className='innovation-title'>
                            <div className='title-img'>
                                <img src={Line} alt="Line Image" loading='lazy'/>
                            </div>
                            <h2>Technological innovation</h2>
                        </div>
                        <p>Our team, proficient in PHP and Java, employed a reverse testing methodology, harnessing the expertise of multiple testers to ensure robust and error-free software. This approach not only enhanced the performance of the platform but also guaranteed its reliability and user-friendliness.</p>
                </div>
                
                <div className='approach-img'>
                    <img src={InnovationImg} alt="StaffWizard innovation" loading='lazy'/>
                </div>
                    
                
            </div>
        </section>
    );
  }
  
  export default Innovation;