import '../components/StaffWizard/staffwizard.css'
import Improvement from "../components/StaffWizard/Improvement";
import Header from "../components/StaffWizard/Header"
import HeaderText from '../components/StaffWizard/HeaderText';
import Approach from '../components/StaffWizard/Approach';
import Innovation from '../components/StaffWizard/Innovation';
import Solutions from '../components/StaffWizard/Solutions';
import ContactSection from "../components/StaffWizard/ContactSection"

import { Helmet } from 'react-helmet-async';

const StaffWizard = () => {
    return (
        <>
            <Helmet>
                <title>Success case: StaffWizard</title>
                <meta name="description" content="How we completely refined the human resources, payroll and shift assignment system, preparing it for launch to a broad customer base."/>
                <meta name="keywords" content="StaffWizard Case, HR Tech, Software Solution, Staffing Platform, Business Technology"/>
            </Helmet>
            <Header />
            <HeaderText />
            <Approach />
            <Innovation />
            <Improvement />
            <Solutions />
            <ContactSection />
        </>
        
    );
};
    
export default StaffWizard;