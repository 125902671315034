import '../components/SoftwareDevelopment/software-development.css'

import Header from "../components/SoftwareDevelopment/Header"

import TransitionPage from '../utils/TransitionPage';
import TechnologyStack from '../components/SoftwareDevelopment/TechnologyStack';
import ChooseService from '../components/SoftwareDevelopment/ChooseService';
import ChooseIndustry from '../components/SoftwareDevelopment/ChooseIndustry';

import { Helmet } from 'react-helmet-async';

const SoftwareDevelopment = () => {
  return (
    <>
      <Helmet>
            <title>Software Development</title>
            <meta name="description" content="Custom software development: web/mobile apps, corporate sites, cloud solutions, database design, and legacy modernization tailored to your needs."/>
            <meta name="keywords" content="custom software, app development, software engineering, web development, technology solutions"/>
      </Helmet>
      <Header />
      <TechnologyStack />
      <ChooseService />
      <ChooseIndustry />
    </>
  );
}

export default TransitionPage (SoftwareDevelopment);