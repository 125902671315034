import '../components/BridgewaterChocolate/bridgewater.css'

import Header from '../components/BridgewaterChocolate/Header';
import Development from '../components/BridgewaterChocolate/Development';
import ContactSection from '../components/BridgewaterChocolate/ContactSection';

import { Helmet } from 'react-helmet-async';

const Bridgewater = () => {
    return (
        <div>
            <Helmet>
                <title>Success case: Bridgewater Chocolate</title>
                <meta name="description" content="The objective was to create an elegant, user-friendly Shopify website that would allow customers to easily purchase chocolates, assemblying a variety of selections."/>
                <meta name='keywords' content='Bridgewater Chocolate, Food Industry Tech, E-commerce Case, Retail Success, Business Innovation'/>  
            </Helmet>
            <Header />
            <Development />
            <ContactSection />
        </div>
    );
}
    
export default Bridgewater;