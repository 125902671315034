function IcoBasketSmile({ baseColor = '#000000', accentColor ='var(--sky-blue)' }) {

    const styleBaseIconStyles = {
        fill: baseColor,
        fillRule:"evenodd",
        clipRule:"evenodd"
    };
    const styleAccentIconStyles = {
        fill: accentColor,
        fillRule:"evenodd",
        clipRule:"evenodd"
    };

      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
            <path style={styleBaseIconStyles} className="styleBase" d="M15,16.5v-2.4c0-2.4,0.9-4.7,2.6-6.4C19.3,6,21.6,5,24,5s4.7,1,6.3,2.7c1.7,1.7,2.6,4,2.6,6.4v2.4
              c6.4,0-2.9,12.6-1,15c2.1,2.7,13.6-4.6,12,3.5l-1,6.3c-1.1,5.6-1.7,8.4-3.6,10.1C37.4,53,34.6,53,29,53H19c-5.6,0-8.4,0-10.4-1.7
              c-2-1.7-2.5-4.5-3.6-10.1L3.5,34C2,25.9,1.2,21.9,3.3,19.2C5.2,16.8,8.6,16.6,15,16.5L15,16.5z M18.6,14.1c0-1.5,0.6-2.8,1.6-3.9
              c1-1,2.4-1.6,3.8-1.6s2.8,0.6,3.8,1.6c1,1,1.6,2.4,1.6,3.9v2.4H18.6V14.1z M31.2,26.3c0.6,0,1.2-0.3,1.7-0.7
              c0.4-0.5,0.7-1.1,0.7-1.7c0-0.6-0.3-1.3-0.7-1.7c-0.4-0.5-1.1-0.7-1.7-0.7c-0.6,0-1.2,0.3-1.7,0.7c-0.4,0.5-0.7,1.1-0.7,1.7
              c0,0.6,0.3,1.3,0.7,1.7C29.9,26,30.5,26.3,31.2,26.3z M19.2,23.8c0,0.6-0.3,1.3-0.7,1.7c-0.4,0.5-1.1,0.7-1.7,0.7s-1.2-0.3-1.7-0.7
              c-0.4-0.5-0.7-1.1-0.7-1.7c0-0.6,0.3-1.3,0.7-1.7c0.4-0.5,1.1-0.7,1.7-0.7s1.2,0.3,1.7,0.7C19,22.6,19.2,23.2,19.2,23.8z"/>
            <path style={styleAccentIconStyles} className="styleAccent" d="M41,8c-8.3,0-15,6.7-15,15c0,8.3,6.7,15,15,15s15-6.7,15-15C56,14.7,49.3,8,41,8z M37,17.2
              c-0.8,0-1.3,0.6-1.4,1c-0.2,0.4-0.3,0.9-0.3,1.3c0,0.5,0.1,0.9,0.3,1.3c0.2,0.3,0.6,1,1.4,1c0.8,0,1.3-0.6,1.4-1
              c0.2-0.4,0.3-0.9,0.3-1.3c0-0.5-0.1-0.9-0.3-1.3C38.2,17.9,37.8,17.2,37,17.2z M43.6,18.2c0.2-0.3,0.6-1,1.4-1c0.8,0,1.3,0.6,1.4,1
              c0.2,0.4,0.3,0.9,0.3,1.3c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.3-0.6,1-1.4,1c-0.8,0-1.3-0.6-1.4-1c-0.2-0.4-0.3-0.9-0.3-1.3
              C43.3,19.1,43.4,18.6,43.6,18.2z M46.7,28.7c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.3,0.1-0.4c0-0.2,0-0.3-0.1-0.5
              c-0.1-0.1-0.1-0.3-0.3-0.4c-0.1-0.1-0.2-0.2-0.4-0.3c-0.1-0.1-0.3-0.1-0.5-0.1c-0.2,0-0.3,0-0.4,0.1c-0.1,0.1-0.3,0.2-0.4,0.3
              c-0.5,0.5-1.2,1-1.9,1.3c-0.7,0.3-1.5,0.4-2.2,0.4s-1.5-0.1-2.2-0.4c-0.7-0.3-1.3-0.7-1.9-1.3c-0.2-0.2-0.5-0.3-0.8-0.3
              c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.8c0,0.3,0.1,0.6,0.3,0.8c1.5,1.5,3.6,2.4,5.7,2.4S45.2,30.2,46.7,28.7z"/>


        </svg>
      );
    }
  
  export default IcoBasketSmile;