import "../components/About/about.css"
import Header from "../components/About/Header";
import JoinForces from "../components/About/JoinForces";
import Locations from "../components/About/Locations";
import OurTeam from "../components/About/OurTeam";
import TeamBusiness from "../components/About/TeamBusiness";

import TransitionPage from '../utils/TransitionPage';
import { Helmet } from 'react-helmet-async';

const About = () => {
    return (
      <>
        <Helmet>
          <title>About Us</title>
          <meta name="description" content="Meet the Advante Digital team: Skilled software engineers, UX designers, and project managers dedicated to pioneering custom digital solutions."/>
          <meta name="keywords" content="about, team, digital expertise, hire a developer, technology leadership, New York, New Jersey"/>
        </Helmet>
          <Header />
          <OurTeam />
          <TeamBusiness />
          <Locations />
          <JoinForces />
      </>
    );
  }
  
  export default TransitionPage( About );