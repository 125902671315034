function IcoSocialLinkedin({ color = '#000000'}) {

const iconStyles = {
    fill: color, // Usar el valor de la variable fill
};

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98" style={iconStyles} >
      <path d="M87.1,0c2.9,0,5.6,1.1,7.7,3.2c2,2,3.2,4.8,3.2,7.7v75.9c0,2.9-1.1,5.6-3.2,7.7c-2,2-4.8,3.2-7.7,3.2H11.2
      c-2.9,0-5.6-1.1-7.7-3.2c-2-2-3.2-4.8-3.2-7.7V10.9c0-2.9,1.1-5.6,3.2-7.7c2-2,4.8-3.2,7.7-3.2H87.1z M84.4,84.1V55.3
      c0-4.7-1.9-9.2-5.2-12.5c-3.3-3.3-7.8-5.2-12.5-5.2c-4.6,0-10,2.8-12.6,7.1v-6H39v45.4h15.1V57.4c0-4.2,3.4-7.6,7.5-7.6
      c2,0,3.9,0.8,5.4,2.2c1.4,1.4,2.2,3.4,2.2,5.4v26.7H84.4z M21.4,30.2c2.4,0,4.7-1,6.4-2.7c1.7-1.7,2.7-4,2.7-6.4
      c0-5-4.1-9.2-9.1-9.2c-2.4,0-4.8,1-6.5,2.7s-2.7,4.1-2.7,6.5C12.2,26.1,16.3,30.2,21.4,30.2z M28.9,84.1V38.7h-15v45.4H28.9z"/>
    </svg>
    
  );
}

export default IcoSocialLinkedin;