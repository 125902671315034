import React, { useState, useEffect } from 'react';
import InfiniteScroll from "react-infinite-scroller";
import CaseStudiesLis from "../../jsons/case-studies.json"
import { Link } from 'react-router-dom';
import IcoArrowThin from "../../images/svg/IcoArrowThin";


import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';


//const CasePath = require.context('../../images/cases', true );

const CasePath = require.context('../../images/caseStudies', true );


const CaseStudiesList = () => {

    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [loadCount, setLoadCount] = useState(10);

    const fetchMoreData = () => {

        setTimeout(() => {
            const newData = CaseStudiesLis.slice(startIndex, startIndex + loadCount);

            if (startIndex + loadCount >= CaseStudiesLis.length) {
                setHasMore(false);
            }

            setItems([...items, ...newData]);
            setStartIndex(startIndex + loadCount);

        }, 750);
    };

    useEffect(() => {
        const initialData = CaseStudiesLis.slice(startIndex, startIndex + loadCount);
        setItems(initialData);
        setStartIndex(startIndex + loadCount);
    }, []);

  return (
    <section className="case-studies-list">
        <div className="containerFull">
            <InfiniteScroll 
                hasMore={hasMore}
                loader={<div className="loader" key={0}>Loading ...</div>}
                loadMore={fetchMoreData}
            >
            {
                items.map( caseStudy => (
                    <div className="case-study-card" key={ caseStudy.id }>
                        <div className="case-study-image">

                            <img 
                                src={CasePath(`./${ caseStudy["featured-image"] }`)} 
                                alt={caseStudy.client} 
                                loading='lazy'
                            />

                        </div>
                        <div 
                            className="case-study-content"
                            style={{ backgroundColor: caseStudy.color + '10'} }
                        >
                            <h6>{ caseStudy.client}</h6>
                            <p>{caseStudy.content}</p>
                            <div className='footer-card'>
                                <ul className="case-study-thumbnail">
                                    
                                        <LightGallery
                                            speed={500}
                                            plugins={[lgThumbnail, lgZoom]}
                                        >
                                            {
                                            caseStudy["gallery-images"].map((caseThumbnail, index) => (
                                                    
                                                    <li key={index}
                                                        data-src={CasePath(`./${caseThumbnail}`)}>
                                                        <img alt="" src={ CasePath(`./${ caseThumbnail }`) } loading='lazy'/>
                                                    </li>
                                                )  )
                                        }
                                        
                                        </LightGallery>
                                            
                                </ul>

                                {caseStudy.relevant ?
                                    <Link to={`/case-studies/${caseStudy.url}`} className='thinButton minicase'>
                                        <span className='case-study-span'>See Case</span>
                                        <IcoArrowThin color='var(--white-color)' />
                                    </Link>
                                    :
                                    null
                                }
                                
                            </div>
                        </div>
                    </div>
                ))
            }
            </InfiniteScroll>
        </div>
    </section>
  );
};

export default CaseStudiesList;
