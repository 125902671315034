import ChooseIndustry from '../components/Ecommerce/ChooseIndustry';
import '../components/Ecommerce/e-commerce.css'
import Header from "../components/Ecommerce/Header";
import OurIntegration from '../components/Ecommerce/OurIntegration';

import TransitionPage from '../utils/TransitionPage';

import { Helmet } from 'react-helmet-async';

const Ecommerce = () => { 
    return(
        <>
            <Helmet>
                <title>E-commerce</title>
                <meta name="description" content="We design a space for you to make yourself known to people, show your products or your services, in the most creative way and that characterizes you."/>
                <meta name='keywords' content='Online Shopping, E-commerce Platforms, Retail Technology, Digital Storefront, E-commerce Solutions, WordPress, Shopify'/>  
            </Helmet>
            <Header />
            <OurIntegration />
            <ChooseIndustry />
            
        </>
    );
}

export default TransitionPage( Ecommerce );