

import StaffPanel from "../../images/staffwizard/staffPanel.webp";
import Grad from "../../images/staffwizard/gradient.webp";

const Header = () => {
    return (
        <section className='staffwizard-header' >

            <div className="containerFull">
                <div className="gradient">
                    <img src={Grad} alt="Gradient" loading='lazy'/>
                </div>
                
                <div className="staff-header-wrapper">        
                    <div className="staff-header-title">
                        <h1> <span className="text-blue-white">StaffWiz</span>ard:</h1>
                        <p><span className="text-blue-white">Workplace Sa</span>fety. <br />
                            Development and Success.</p>
                    </div>
                </div>
                    <div className="header-img">
                        <img src={StaffPanel} alt="Staffwizard App" loading='lazy'/>
                    </div>
                
            </div>
        </section>
    );
  }
  
  export default Header;