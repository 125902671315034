
import JS from '../../images/judigo-legal/js.webp'
import PHP from '../../images/judigo-legal/php.webp'
import JAVA from '../../images/judigo-legal/java.webp'
import JS2 from '../../images/judigo-legal/js2.webp'
import CSS from '../../images/judigo-legal/css.webp'
import HTML from '../../images/judigo-legal/html.webp'

const Technologies = () => {
    return (
        <section className='judigo-tech' >
            <div className="containerFull">
                <div className="judigo-tech-wrapper">        
                    <div className="judigo-tech-title">
                        <hr />
                        <h2>Technologies Used for <span>Development</span></h2>
                                                 
                    </div>
                    <div className="judigo-tech-img">

                            <div>
                                <img src={JS} alt="JS" loading='lazy'/>
                            </div>
                            <div>
                                <img src={PHP} alt="Php" loading='lazy'/>
                            </div>
                            <div>
                                <img src={JAVA} alt="Java" loading='lazy'/>
                            </div>
                            <div>
                                <img src={JS2} alt="Javascript" loading='lazy'/>
                            </div>
                            <div>
                                <img src={CSS} alt="Css" loading='lazy'/>
                            </div>
                            <div>
                                <img src={HTML} alt="Html" loading='lazy'/>
                            </div>

                    </div>
                    
                </div>
                    
                
            </div>
        </section>
    );
  }
  
  export default Technologies;