
const Solution = () => {
    return (
        <section className='judigo-solution' >
            <div className="containerFull">
                <div className="judigo-solution-wrapper">        
                    <div className="judigo-solution-title">
                        <h2><span>The Solution:</span> A User-Friendly, Efficient Legal Platform</h2>
                        <hr />                            
                    </div>
                    <div className="judigo-solution-text">
                        <p>Advante Digital took on the challenge of creating this innovative platform. We developed a system where consumers with legal queries could effortlessly submit their inquiries. In response, an attorney specializing in the relevant field of law could initiate a conversation with the potential client. This setup functioned like an introductory call, providing an opportunity for lawyers to gain new leads and for consumers to gauge whether they wish to continue with a particular attorney.</p>
                    </div>
                    
                </div>
                    
                
            </div>
        </section>
    );
  }
  
  export default Solution;