import { Link } from "react-router-dom";
import IcoArrowThin from "../../images/svg/IcoArrowThin";

import PhoneSpa from "../../images/spa-live/phone-spa.webp";
import Tech from "../../images/spa-live/technologies.webp";
import Grad1 from "../../images/spa-live/grad1.webp";
import Grad2 from "../../images/spa-live/grad2.webp";
import Grad3 from "../../images/spa-live/grad3.webp";

const Header = () => {
    return (
        <section className='spa-header' >
            
            
            <div className="containerFull">
                <div className="gradient g-1">
                    <img src={Grad1} alt="Gradient" loading="lazy" />
                </div>
                <div className="gradient g-2">
                    <img src={Grad2} alt="Gradient" loading="lazy" />
                </div>
                <div className="gradient g-3">
                    <img src={Grad3} alt="Gradient" loading="lazy" />
                </div>
                <div className="spa-header-wrapper">
                    <div className="spa-header-title">
                        <h1>Transforming Aesthetics with <br />
                            <span>MySpaLive</span>
                        </h1>
                    </div>
                    <div className="spa-header-content">
                        <section className="content-header-left">
                            <div className="content-text">
                                <p>Discover how MySpaLive revolutionized the training process for doctors seeking expertise in neurotoxin injections. Explore the success stories of doctors who elevated their skills through our mobile application, aced examinations, and obtained certifications that set them apart in the field.</p>
                            </div>                            
                        </section>
                        <section className="header-image">
                            <div className="phone-container">
                                <img src={PhoneSpa} alt="SpaLive Phone App" loading="lazy" />
                            </div>
                        </section>
                        
                        <div className="contact-block">
                            <p>Would You like something similar?</p>
                            <div className="project-header-button">
                                <Link to={`/contact-us`} className='thinButton'>
                                    <span>Tell us about your project</span>
                                    <IcoArrowThin color='var(--white-color)' />
                                </Link>
                                <a >
                                    
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="project-creation">
                        <div className="project-creation-title">
                            <h1>
                                Steps that were followed at the time of
                                <br /><span>project creation</span>
                            </h1>
                        </div>
                        <section className="project-creation-content">
                            <div className="project-list">
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>1.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Definition of the Project Scope.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Identification of key objectives: medical training, doctor-patient connection, telemedicine, appointment scheduling.
                                        <br />Establishment of functional and non-functional requirements.</p>
                                    </div>
                                </div>
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>2.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Application Design and Architecture.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Creation of wireframes and prototypes.
                                        <br />Development of the application architecture to ensure scalability and efficiency.</p>
                                    </div>
                                </div>
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>3.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Training Module Development.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Implementation of the educational module for physicians.
                                        <br />Integration of evaluations and certifications.</p>
                                    </div>
                                </div>
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>4.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Construction of the Connectivity Platform.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Development of the application for physicians and injectors.
                                        <br />Implementation of search functions and scheduling of video calls.</p>
                                    </div>
                                </div>
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>5.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Incorporation of Telemedicine Technologies.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Development of doctor-patient video call functions.
                                        <br />Guarantee of security and regulatory compliance in communications.</p>
                                    </div>
                                </div>
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>6.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Patient Empowerment and Appointment Scheduling.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Creation of functions for patients to schedule appointments.
                                        <br />Facilitating neurotoxin injections at patient-preferred locations.</p>
                                    </div>
                                </div>
                                <div className="project-list-item">
                                    <div className="project-list-title">
                                        <div className="project-list-item-number">
                                            <span>7.</span>
                                        </div>
                                        <div className="project-list-item-text">
                                            <p>Results and Lessons Learned.</p>
                                        </div>
                                    </div>
                                    <div className="project-list-content">
                                        <p>Analysis of results obtained.
                                        <br />Lessons learned during development and implementation.</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="technologies-used">
                                <div className="technologies-title">
                                    <h3>Technologies Used for Development</h3>
                                    <p>The MySpaLive application was built by integrating various technologies that ensured optimal performance and an exceptional user experience. Key technologies used include:</p>
                                </div>
                                <div className="technologies-image">
                                    <img src={Tech} alt="Technologies Used" loading="lazy"/>
                                </div>
                            </div>
                        </section>
                    </div>
                    
                        
                        
                        
                    
                </div>
                
            </div>
        </section>
    );
  }
  
  export default Header;