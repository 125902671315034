import Features from '../../images/rowdy-energy/rowdy-features.webp';
import BgFruits from '../../images/rowdy-energy/fruits.webp';
import Circle from '../../images/rowdy-energy/purple-circle.webp'
import RowdyApp from '../../images/rowdy-energy/rowdy-outcome.webp'

const RowdyFeatures = () => {
    return (
        <>
            <section className="rowdy-features-wrapper">
                <div className='containerFull'>
                    <div className="rowdy-features__img">
                        <img src={Features} alt="Rowdy Energy Drink Features" loading='lazy'/>
                    </div>
                    <div className="rowdy-features__text">
                        <h2>Key features of the website</h2>
                        <ul>
                            <li>
                                Highly Animated Interface: 
                                <span> Each section of the website was meticulously animated, keeping in line with the vibrant and energetic theme of Rowdy Energy Drink.</span>
                            </li>
                            <li>
                                Responsive Design: 
                                <span> We ensured that the website was fully responsive, providing a seamless experience on all devices, from desktops to smartphones.</span>
                            </li>
                            <li>
                                Diverse Pages and Functions:  
                                <span> Apart from the main pages, the website included various order pages, an FAQ section, subscription and login capabilities, all integrated within the animated theme.</span>
                            </li>
                            <li>
                                Technical Backbone:  
                                <span> The platform was built using HTML, CSS, and JavaScript, hosted on Netlify servers, ensuring fast and reliable performance.</span>
                            </li>
                        </ul>
                    </div>             
                </div>
                           
            </section>

            <section className="rowdy-process-wrapper">
                <div className="fruits-bg">
                    <img src={BgFruits} alt="Fruits Background" loading='lazy'/>
                    <img src={Circle} alt="Red Circle Background" className='circle'loading='lazy'/>
                    <div className="rodwy-process__text">
                        <h2>The development and testing process</h2>
                        <p>The development process spanned five months, involving multiple rounds of rigorous testing. Our focus was on compatibility across different devices and browsers, ensuring that animations and functionalities remained flawless regardless of the platform used.</p>
                    </div>
                </div>
            </section>

            <section className="rowdy-outcome">
                <div className="containerFull">
                        <div className="rodwy-outcome__img">
                            <img src={RowdyApp} alt="Rowdy Energy Drink App" loading='lazy' />
                        </div>
                        <div className="rodwy-outcome__text">
                            <h2>Our Outcome</h2>
                            <p>The collaboration between Advante Digital and Rowdy Energy Drink resulted in a website that truly stands out in the digital landscape. Our expertise in web development allowed us to execute a highly complex, animated design, delivering a website that not only matched but exceeded the client's expectations. This project exemplifies our ability to handle challenging designs and our commitment to delivering high-quality, innovative digital solutions.</p>
                        </div>
                        
                    </div>
            </section>
        </>
            
    );
}

export default RowdyFeatures;