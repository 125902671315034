import { Swiper, SwiperSlide } from 'swiper/react';
import { Link, useNavigate } from 'react-router-dom';

import CasesList from '../../jsons/case-studies.json';

import SpaPCs from '../../images/caseStudies/ComputerSPA.webp';
import JudigoLegal from '../../images/caseStudies/ComputerJudigoLegal.webp';
import BirdgeWater from '../../images/caseStudies/ComputerBridgewater.webp';
import StaffWizard from '../../images/caseStudies/ComputerStafWizard.webp';
import RowdyEnergy from '../../images/caseStudies/ComputerRowdy.webp';
import SeagramsEsc from '../../images/caseStudies/ComputerSeagrams.webp';
import AllNation from '../../images/caseStudies/ComputerANS.webp';


import IcoArrowThin from "../../images/svg/IcoArrowThin";

import { Autoplay, Pagination } from 'swiper/modules';

const relevantCases = CasesList.filter(item => item.hasOwnProperty("relevant"));

const Header = () => {

    const navigate = useNavigate();

    const handleChange = (event, url) => {
        navigate(`/case-studies/${url}`);
    };
    
  return (
    <section className="case-studies-header">

        <div className="bgCircle bgCircle-01"></div>
        <div className="bgCircle bgCircle-02"></div>
        <div className="bgCircle bgCircle-03"></div>
        <div className="bgCircle bgCircle-04"></div>

        <div className="bgr-noise"></div>
        <div className="containerFull">
            <div className="case-studies-header-headline">
                <h1>CASE <br /><span className='studies-title'>STUDIES.</span></h1>
                <p>Our team has provided many software solutions to multiple companies focused on industries such as, healthcare, e-commerce, HHRR, Law, Finance, marketplaces, staffing, marketing, legal issues, among others, we also focus on designing our own digital projects.</p>
                <Link to={`/contact-us`} className='thinButton'>
                    <span>Tell us about your project</span>
                    <IcoArrowThin color='var(--white-color)' />
                </Link>
            </div>
            <div className="case-studies-header-swiper">
                <Swiper
                    pagination={{
                        clickable: true,
                    }}
                    autoplay={{
                        delay: 2500,
                        disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay, Pagination]}
                    className='case-studio-swiper'
                >
                    
                    <SwiperSlide onClick={( event ) => handleChange( event, 'spa-live')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>Healthcare</h6>
                            <img 
                                src={ SpaPCs }
                                alt='Spa Live'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={( event ) => handleChange( event, 'judigo-legal')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>Legal Tech</h6>
                            <img 
                                src={ JudigoLegal }
                                alt='Judigo Legal'
                                loading='lazy'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={( event ) => handleChange( event, 'bridgewater-chocolate')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>E-commerce</h6>
                            <img 
                                src={ BirdgeWater }
                                alt='Bridgewater Chocolate'
                                loading='lazy'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={( event ) => handleChange( event, 'staffwizard')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>Finance</h6>
                            <img 
                                src={ StaffWizard }
                                alt='StaffWizard'
                                loading='lazy'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={( event ) => handleChange( event, 'rowdy-energy-drink')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>Retail</h6>
                            <img 
                                src={ RowdyEnergy }
                                alt='Rowdy Energy Drink'
                                loading='lazy'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={( event ) => handleChange( event, 'seagrams-escapes')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>Digital Marketing</h6>
                            <img 
                                src={ SeagramsEsc }
                                alt='Seagrams Escapes'
                                loading='lazy'
                            />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide onClick={( event ) => handleChange( event, 'all-nation-security')} className='slide'>
                        <div className='case-thumbnail'>
                            <h6>Human Resources</h6>
                            <img 
                                src={ AllNation }
                                alt='All Nation Security'
                                loading='lazy'
                            />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </div>
            
   
        </div>
    </section>
  );
};

export default Header;
