import '../components/SoftwareProductDesign/software-design.css'
import Header from "../components/SoftwareProductDesign/Header";
import Services from '../components/SoftwareProductDesign/Services';
import Tools from '../components/SoftwareProductDesign/Tools';
import ChooseIndustry from '../components/SoftwareProductDesign/ChooseIndustry';

import TransitionPage from '../utils/TransitionPage';

import { Helmet } from 'react-helmet-async';

const SoftwareProductDesign = () => { 
    return(
        <>
            <Helmet>
                <title>Software Product Design</title>
                <meta name="description" content="Turning ideas into innovation, and vision into digital reality."/>
                <meta name="keywords" content="Product Design, UX/UI Design, Software UI, Digital Design, User Experience"/>
            </Helmet>
            <Header />
            <Services />
            <Tools />
            <ChooseIndustry />
        </>
    );
}

export default TransitionPage (SoftwareProductDesign);