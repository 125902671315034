import TimeSensitive from '../../images/judigo-legal/time-sensitive.webp';
import Accessibility from '../../images/judigo-legal/access.webp';
import Technical from '../../images/judigo-legal/technical.webp';
import Expansion from '../../images/judigo-legal/expansion.webp';

const Features = () => {
    return (
        <section className='features-wrapper-judigo'>
            <div className="bgr-lines"></div>
            <div className="containerFull">
                <div className="judigo-features">        
                    <div className="judigo-features-cards">
                        <div className="feature-card black">
                            <div>
                                <h4>Time-Sensitive Approach with AI Integration</h4>
                                <p>Understanding the urgency of the project, we implemented a time-limited feature to simulate an introductory consultation. Additionally, we integrated artificial intelligence to assist attorneys in organizing their information efficiently. This feature enhances the platform's usability, making it a valuable tool for both lawyers and consumers.</p>
                            </div>
                        </div>  
                        <div className="feature-card img">
                            <img src={TimeSensitive} alt='Time Sensitive Image' loading='lazy'></img>
                        </div> 

                        <div className="feature-card img">
                            <img src={Accessibility} alt='Accessibility Image' loading='lazy'></img>
                        </div>
                        <div className="feature-card green">
                            <div>
                                <h4>Accessibility and Visibility</h4>
                                <p>The platform was designed for accessibility, enabling users to connect via both mobile phones and computers. It features a comprehensive lawyer directory, allowing consumers to easily find and evaluate their experiences with different attorneys. For law firms, the platform offers increased visibility through a directory listing, improving their online presence and searchability on Google.</p>
                            </div>  
                        </div>  

                        <div className="feature-card black">
                            <div>
                                <h4>Technical Excellence</h4>
                                <p>Built using React and Node.js, the platform leverages Firebase for real-time synchronization of chat conversations, ensuring seamless communication. Looking ahead, we plan to integrate Stripe for processing payments from attorneys, further enhancing the platform's functionality.</p>
                            </div>    
                        </div>  
                        <div className="feature-card img">
                            <img src={Technical} alt='Technical Image' loading='lazy'></img>
                        </div> 

                        <div className="feature-card img">
                            <img src={Expansion} alt='Expansion Image' loading='lazy'></img>
                        </div>
                        <div className="feature-card green">
                            <div>
                                <h4>Rapid Development and Future Expansion</h4>
                                <p>The project's most significant challenge was its expedited timeline. We successfully delivered a Minimum Viable Product (MVP) within this tight schedule, laying the foundation for future expansion into new states and additional features.</p>
                            </div>    
                        </div>  
                    </div>
                    
                </div>
                    
                
            </div>
        </section>
            
    );
  }
  
  export default Features;