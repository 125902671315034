function IcoSocialInstagram({ color = '#000000'}) {

const iconStyles = {
    fill: color, // Usar el valor de la variable fill
};

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 98 98" style={iconStyles} >
      <path d="M54.2,0.3c3.6,0,7.1,0,10.7,0.1l0.9,0c1.1,0,2.2,0.1,3.5,0.1c5.2,0.2,8.7,1.1,11.8,2.3c3.2,1.2,5.9,2.9,8.6,5.6
	c2.5,2.4,4.4,5.4,5.6,8.6c1.2,3.1,2,6.7,2.3,11.9c0.1,1.3,0.1,2.4,0.1,3.5l0,0.9C97.9,37,98,40.6,98,44.1l0,3.6v6.4
	c0,3.6,0,7.1-0.1,10.7l0,0.9c0,1.1-0.1,2.2-0.1,3.5c-0.2,5.2-1.1,8.7-2.3,11.8c-1.2,3.3-3.1,6.2-5.6,8.7c-2.4,2.5-5.4,4.4-8.6,5.6
	c-3.1,1.2-6.7,2-11.8,2.3c-1.3,0.1-2.4,0.1-3.5,0.1l-0.9,0c-3.6,0.1-7.1,0.1-10.7,0.1l-3.6,0h-6.4c-3.6,0-7.1,0-10.7-0.1l-0.9,0
	c-1.2,0-2.3-0.1-3.5-0.1c-5.2-0.2-8.7-1.1-11.9-2.3c-3.3-1.2-6.2-3.1-8.6-5.6c-2.5-2.4-4.4-5.4-5.6-8.7c-1.2-3.1-2-6.7-2.3-11.8
	c-0.1-1.2-0.1-2.3-0.1-3.5l0-0.9c-0.1-3.6-0.1-7.1-0.1-10.7v-10c0-3.6,0-7.1,0.1-10.7l0-0.9c0-1.1,0.1-2.2,0.1-3.5
	c0.2-5.2,1.1-8.7,2.3-11.9C4.1,13.9,6,11,8.5,8.5c2.4-2.5,5.4-4.4,8.6-5.6c3.1-1.2,6.7-2,11.9-2.3l3.5-0.1l0.9,0
	c3.6-0.1,7.1-0.1,10.7-0.1L54.2,0.3z M49.1,24.8c-3.2,0-6.4,0.6-9.4,1.8c-3,1.2-5.7,3-8,5.3c-2.3,2.3-4.1,5-5.4,8s-1.9,6.2-1.9,9.4
	c0,3.2,0.6,6.4,1.9,9.4c1.2,3,3.1,5.7,5.4,8c2.3,2.3,5,4.1,8,5.3c3,1.2,6.2,1.8,9.4,1.8c6.5,0,12.7-2.6,17.3-7.1
	c4.6-4.6,7.1-10.8,7.1-17.3c0-6.5-2.6-12.7-7.1-17.3C61.8,27.3,55.6,24.8,49.1,24.8z M49.1,34.5c1.9,0,3.9,0.3,5.7,1
	c1.8,0.7,3.5,1.8,4.8,3.2c1.4,1.4,2.5,3,3.2,4.8c0.8,1.8,1.1,3.7,1.1,5.7c0,1.9-0.4,3.9-1.1,5.7c-0.8,1.8-1.9,3.4-3.2,4.8
	c-1.4,1.4-3,2.4-4.8,3.2c-1.8,0.7-3.7,1.1-5.7,1c-3.9,0-7.6-1.5-10.4-4.3c-2.7-2.7-4.3-6.5-4.3-10.4s1.5-7.6,4.3-10.4
	C41.5,36.1,45.3,34.5,49.1,34.5L49.1,34.5z M74.8,17.4c-1.6,0.1-3.1,0.7-4.2,1.9c-1.1,1.1-1.7,2.7-1.7,4.2c0,1.6,0.6,3.1,1.7,4.2
	c1.1,1.1,2.6,1.8,4.2,1.9c1.6,0,3.2-0.6,4.3-1.8c1.1-1.1,1.8-2.7,1.8-4.3s-0.6-3.2-1.8-4.3C77.9,18.1,76.4,17.4,74.8,17.4L74.8,17.4
	z"/>
    </svg>
    
  );
}

export default IcoSocialInstagram;