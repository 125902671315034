import '../components/Seagrams/seagrams.css';
import Header from '../components/Seagrams/Header';
import Features from '../components/Seagrams/Features';
import ContactSection from '../components/Seagrams/ContactSection';

import { Helmet } from 'react-helmet-async';

const Seagrams = () => {
    return (
        <div>
            <Helmet>
                <title>Success case: Seagram's Escapes</title>
                <meta name="description" content="The path to create an higly animated site with a beautiful design and attract customers. "/>
                <meta name='keywords' content='Seagrams Escapes, Alcohol Brand, Marketing Case, Digital Campaign, Brand Success'/>
            </Helmet>
            <Header />
            <Features />
            <ContactSection />
        </div>
    );
};
    
export default Seagrams;