import City from '../../images/all-nation/city-img.webp'
import FeaturesImg from '../../images/all-nation/features-img.webp'

const Deployment = () => {
    return (
        <>
            <section className='deployment-wrapper'>
                <hr />
                <div className='containerFull'>
                    <div className='deployment-header'>
                        <div className='deployment-img'>
                            <img src={City} alt="All Nation App" loading='lazy'/>
                        </div>
                        <div className='deployment-text'>
                            <h2><span>Deployment</span> and Success in California</h2>
                            <p>The revamped system was successfully launched in California. It stood out for its efficiency in handling onboarding processes, shift management, and detailed reporting through an intuitive administration panel. The platform's success was marked by its widespread adoption among All Nation Security's staff, including supervisors, managers, and agents.</p>
                        </div>
                        
                    </div>
                </div>
            </section>
            <section className='features-wrapper'>
                <div className='containerFull'>
                    <div className='features-text'>
                        <h2><span>Key Features </span> and Outcomes</h2>
                        <ul className='features'>
                            <li className='feature'>
                                <p><b>Enhanced Dashboard:</b> Our redesigned dashboard allowed for real-time tracking of employees, shift assignments, and comprehensive reporting.</p>
                            </li>
                            <li className='feature'>
                                <p><b>User-Friendly Mobile Application:</b> The React Native app was optimized for ease of use, enabling staff to access critical information and functionalities on the go.</p>
                            </li>
                            <li className='feature'>
                                <p><b>Robust Backend Support: </b> The PHP backend was strengthened to support the new features, ensuring seamless operation and scalability.</p>
                            </li>
                        </ul>
                    </div>
                    <div className='features-img'>
                        <img src={FeaturesImg} alt="All Nation Security Features" loading='lazy' />
                    </div>
                </div>

            </section>
            <section className='impact-wrapper'>
                <div className='impact-bg'></div>
                <div className='containerFull'>
                    <div className='impact-text'>
                        <h2><span>Revitalizing Operational Excellence: </span> Advante Digital’s Transformative Impact on All Nation Securitys.</h2>
                        <p>Advante Digital's intervention transformed All Nation Security's operational capabilities. By completing and enhancing their system, we provided them with a powerful tool to manage their workforce efficiently. This project highlights our expertise in revitalizing incomplete systems, adding significant value through technical proficiency and a deep understanding of our client's needs. The successful deployment in California is a testament to our commitment to delivering high-quality, customized software solutions.</p>
                    </div>
                </div>

            </section>
        </>
        
            
        );
};
    
export default Deployment;
