import '../components/ArtificialIntelligence/artificial-intelligence.css'
import ChooseIndustry from '../components/ArtificialIntelligence/ChooseIndustry';
import CustomSolutions from '../components/ArtificialIntelligence/CustomSolutions';
import Header from "../components/ArtificialIntelligence/Header"

import TransitionPage from '../utils/TransitionPage';

import { Helmet } from 'react-helmet-async';

const ArtificialIntelligence = () => {
  return (
    <>
      <Helmet>
          <title>Artificial Intelligence</title>
          <meta name="description" content="Custom AI solutions: dynamic text generation, delivery optimization, and text classification/sentiment analysis to advance your business."/>
          <meta name='keywords' content='AI Solutions, Machine Learning, AI Technology, Intelligent Systems, AI Applications, Custom AI chat development'/>  
      </Helmet>
        <Header />
        <CustomSolutions />
        <ChooseIndustry />
    </>
  );
}

export default TransitionPage ( ArtificialIntelligence );