import '../components/PlatformIntegrations/platform-integrations.css'

import Header from "../components/PlatformIntegrations/Header"


import TransitionPage from '../utils/TransitionPage';
import ChooseIndustry from '../components/PlatformIntegrations/ChooseIndustry';
import Services from '../components/PlatformIntegrations/Services';
import Technologies from '../components/PlatformIntegrations/Technologies';

import { Helmet } from 'react-helmet-async';

const PlatformIntegrations = () => {
  return (
    <>
      <Helmet>
          <title>Platform Integrations</title>
          <meta name="description" content="We are leaders in the convergence of innovation and business efficiency, integrating multiple tools like payment methods, video calls, chats, and more custom integrations. "/>
          <meta name='keywords' content='System Integration, Software Platforms, Data Integration, API Services, Business Integration, Stripe integration, Google email integration, Zoom integration, Video call integration, Chat integration'/>
      </Helmet>
      <Header />
      <Technologies />
      <ChooseIndustry />
    </>
  );
}

export default TransitionPage(PlatformIntegrations ); 