import HeaderImg from '../../images/bridgewater/header-img.webp';
import ChallengeApp from '../../images/bridgewater/challenge-app.webp';
import Features from '../../images/bridgewater/features.webp';
import Landing2 from '../../images/bridgewater/bridge-landing2.webp';

const Header = () => {
    return (
        <section className='bridgewater-header' >
            <div className="containerFull">
                    <div className="bridgewater-header-text">
                        <div className="bridgewater-title">
                            <h1>Bridgewater Chocolate</h1>
                            <h2>Click, taste, enjoy!</h2>
                        </div>
                        <p className="bridgewater-title-p">Bridgewater Chocolate, renowned for its exquisite chocolate and confectionery offerings, sought to enhance its online retail presence. The objective was to create an elegant, user-friendly Shopify website that would allow customers to easily purchase chocolates, assemble a variety of selections, and enjoy a seamless gifting experience.</p>
                    </div>

                    <div className="bridgewater-landing">
                        <img src={HeaderImg} alt="Bridgewater Chocolate Site" loading='lazy'/>
                    </div>

                    <div className="bridgewater-challenge">
                        <div className="challenge-site">
                            <img src={ChallengeApp} alt="Bridgewater Chocolate Challenge" loading='lazy' />
                        </div>
                        <div className="challenge-points">
                            <div>
                                <h3><span>The Challenge:</span> Crafting a Refined Online Boutique</h3>
                                <p>The challenge was to embody the luxury and quality of Bridgewater Chocolate in a digital format. This meant translating an elegant design provided by Sixplusone into a functional, intuitive online store. Our goal was to ensure that every aspect of the website reflected the brand's commitment to excellence and provided a delightful user experience.</p>
                            </div>
                            <div>
                                <h3><span>Our Solution:</span> A Premium Shopify Website</h3>
                                <p>Advante Digital developed a Shopify website that not only met but exceeded Bridgewater Chocolate's expectations. The site was designed to be as beautiful as it is functional, with a focus on the user experience and operational efficiency.</p>
                            </div>
                        </div>
                        
                    </div>

                    <div className="bridgewater-features">
                        
                        <h3>Key Features of the Website</h3>
                        <div className='features-container'>
                            <div className="bridgewater-list">
                                <ul>
                                    <li>
                                        Elegant Design: 
                                        <span>The website boasts a sophisticated design, reflecting the luxury of Bridgewater Chocolate's products. The layout, color scheme, and typography were all carefully chosen to create an inviting and upscale online environment.</span>
                                    </li>
                                    <li>
                                        User-Friendly Shopping: 
                                        <span>Customers can easily browse and purchase chocolates, create custom assortments, and explore detailed product descriptions and ingredients.</span>
                                    </li>
                                    <li>
                                        Enhanced Gifting Options: 
                                        <span>The site enables users to send gifts to multiple recipients simultaneously, making it easy to share Bridgewater Chocolate's offerings with friends and family.</span>
                                    </li>
                                    <li>
                                        Integrated Logistics: 
                                        <span>Leveraging Shopify's robust platform, the website connects to systems for labeling packages, managing taxes, and handling other logistical details, including location-based functionality.</span>
                                    </li>
                                    <li>
                                        Easy Product Management: 
                                        <span>Bridgewater Chocolate's staff can effortlessly update product listings, add new categories, and manage the selection of chocolates, ensuring the site remains up-to-date and enticing.</span>
                                    </li>
                                </ul>
                            </div>
                            <div className='bridge-features-img'>
                                <img src={Features} alt="Bridgewater Chocolate Features" loading='lazy' />
                            </div>
                        </div>
                            
                    </div>

                    <div className="bridgewater-experience">
                        <img src={Landing2} alt="Bridgewater Chocolate Landing Page" loading='lazy'/>
                    </div>
            </div>
                
        </section>
    );
}
    
export default Header;
