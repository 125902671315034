import SeagramsLanding from '../../images/seagrams/seagrams-landing.webp'
import Bottle from '../../images/seagrams/bottle.webp'
import SolutionWave from '../../images/seagrams/solution-wave.webp'
const Header = () => {

    return (
        <>
            <section className="seagrams-header-wrapper">
                <div className="containerFull">
                    <div className="seagrams-header__title">
                        <h1>Seagrams</h1>
                        <h2>Sip into joy!</h2>
                    </div>
                    <p>Seagram's Escapes, a popular brand known for its flavorful beverage selections, sought an online platform that would vividly represent the lively and refreshing nature of their products. The goal was to create a highly animated, engaging website that would not only showcase their varied product lines but also offer a unique and interactive experience to visitors.</p>
                </div>
            </section>

            <section className="seagrams-challenge-wrapper">
                <div className="seagrams-challenge__container">
                    <div className='containerFull'>
                        <div className="seagrams-challenge__content">
                            <h2>The challenge: <span>bringing liquid animation to life</span></h2>
                            <p>The primary challenge was to develop a site that encapsulates the essence of Seagram's beverages — the fluidity, bubbles, and vibrant colors of their drinks. We aimed to create animations that mimic the movement of liquid and the effervescence of bubbles to give users a visual taste of what Seagram's offers.</p>
                        </div>
                        <div className="seagrams-challenge__img">
                            <img src={SeagramsLanding} alt="Seagrams Escapes Landing" loading='lazy'/>
                        </div>
                    </div>
                        
                </div>
            </section>

            <section className="seagrams-solution-wrapper">
                <div className="containerFull">
                    <div className="seagrams-solution__text">
                        <h2>Our Solution: <span>a dynamic, interactive website</span></h2>
                        <p>Advante Digital developed an animated, immersive website that represents each of Seagram's multiple flavors with a distinct and captivating design. The site was crafted to provide an exploratory experience, with a virtual curtain that reveals the diverse product lines and invites users into the world of Seagram's Escapes.</p>
                    </div>
                    <div className="seagrams-solution__img">
                        <img src={Bottle} alt="Seagrams Escapes Solution" loading='lazy'/>
                    </div>
                </div>
                <img src={SolutionWave} alt="White Wave"  className='solution-wave' loading='lazy'/>
            </section>
        </>
    )
}

export default Header;