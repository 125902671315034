import "../components/Articles/articles.css"
import ArticlesList from "../components/Articles/ArticlesList";


import TransitionPage from '../utils/TransitionPage';
import { Helmet } from 'react-helmet-async';

const Articles = () => {
    return (
        <>
            <Helmet>
                <title>Articles</title>
                <meta name="description" content="Discover our latest articles on cutting-edge software, new payment systems, programming languages, and software architecture across industries."/>
                <meta name='keywords' content='tech articles, industry insights, latest trends, software updates, digital innovation'/>  
            </Helmet>
            <ArticlesList />
        </>
    );
  }
  
  export default TransitionPage( Articles );