import '../components/SpaLive/spa-live.css'
import Development from "../components/SpaLive/Development";
import Header from "../components/SpaLive/Header"
import ContactSection from "../components/SpaLive/ContactSection"

import { Helmet } from 'react-helmet-async';

const SpaLive = () => {
    return (
        <>
            <Helmet>
                <title>Success case: MySpaLive</title>
                <meta name="description" content="Discover how we built MySpaLive; a company that revolutionized the training process for doctors seeking expertise in neurotoxin injections. "/>
                <meta name="keywords" content="MySpaLive, neurotoxin injections, medical training, medical education, medical technology, medical software"/>
            </Helmet>
            <Header />
            <Development />
            <ContactSection />
        </>
        
    );
};
    
export default SpaLive;