import '../components/DigitalMarketing/digital-marketing.css'
import Header from "../components/DigitalMarketing/Header";
import ChooseService from '../components/DigitalMarketing/ChooseService';
import DigitalTools from '../components/DigitalMarketing/DigitalTools';
import ChooseIndustry from '../components/DigitalMarketing/ChooseIndustry';
import TransitionPage from '../utils/TransitionPage';

import { Helmet } from 'react-helmet-async';

const DigitalMarketing = () => { 
    return(
        <>
            <Helmet>
                <title>Digital Marketing</title>
                <meta name="description" content="We build your brand. Irresistible and distinct."/>
                <meta name='keywords' content='Online Marketing, SEO, Social Media Marketing, Digital Advertising, Content Marketing'/>  
            </Helmet>
            <Header />
            <ChooseService />
            <DigitalTools />
            <ChooseIndustry />
        </>
    );
}

export default TransitionPage ( DigitalMarketing );