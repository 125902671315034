
const Improvement = () => {


    return (
        <section className='improvement' >

            <div className="containerFull">
                
                <div className="improvement-wrapper">        
                    <div className="improvement-title">
                        <h2>Continuous Improvement at <br /><span>StaffWizard.</span></h2>
                    </div>
                    <div className="improvement-points">
                        <div className="lines">
                            <hr />
                            <hr />
                        </div>
                        <div className="point">
                            <div className="point-number">01.</div>
                            <div className="point-text">
                                <h4>Geographic Reach:</h4>
                                <p>StaffWizard has transcended its origins, now operating seamlessly in additional states, geographic diversification ensures accessibility and utility in diverse regions.</p>
                            </div>
                        </div>
                        <div className="point">
                            <div className="point-number">02.</div>
                            <div className="point-text">
                                <h4>New Features:</h4>
                                <p>Incident reporting, proactive and efficient shift system and advanced reporting tools.</p>
                            </div>
                        </div>
                        <div className="point">
                            <div className="point-number">03.</div>
                            <div className="point-text">
                                <h4>Market Leadership:</h4>
                                <p>Our dedication ensures that customers experience cutting-edge solutions and services, our continuous efforts in advancing StaffWizard go beyond maintenance, we are actively shaping its trajectory, introducing new features, and providing unparalleled service in an ever-evolving marketplace.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
    );
  }
  
  export default Improvement;