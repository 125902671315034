function IcoCalendarDollar({ baseColor = '#000000', accentColor ='var(--sky-blue)' }) {

    const styleBaseIconStyles = {
        fill: baseColor, 
    };
    const styleAccentIconStyles = {
        fill: accentColor,
        fillRule:"evenodd",
        clipRule:"evenodd"
    };

      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">

            <path style={styleBaseIconStyles} className="styleBase" d="M41.2,25.9H1.8c-0.4,0-0.8,0.4-0.8,0.8V55c0,0.4,0.4,0.8,0.8,0.8h39.4c0.5,0,0.8-0.4,0.8-0.8V26.7
              C42,26.2,41.6,25.9,41.2,25.9z M14.7,49.8c-1.4,0-2.9-0.3-4-0.7c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.2-0.1-0.3l0.4-2.8
              c0-0.1,0-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c1.3,0.5,2.3,0.7,3.7,0.7
              c1.4,0,2.4-0.7,2.4-1.6c0-1.2-0.6-1.8-3.7-2c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3v-2.8c0-0.2,0.2-0.4,0.4-0.4
              c2.7-0.2,3-1,3-1.6c0-0.4,0-1.1-1.8-1.1c-1,0-2,0.3-3.1,0.8c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1
              c0-0.1-0.1-0.1-0.1-0.2L10.8,34c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.1,0.2-0.2c1.2-0.6,2.8-0.9,4.7-0.9c3.3,0,5.5,1.5,5.5,3.8
              c0,1.7-0.9,3-2.7,3.8c1.6,0.6,3.2,1.7,3.2,4C21.7,47.7,18.9,49.8,14.7,49.8z M31.9,49.1c0,0.1,0,0.2-0.1,0.3
              c-0.1,0.1-0.2,0.1-0.3,0.1h-3.4c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1s0-0.1,0-0.2V38.1l-2.2,0.8
              c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2l-0.4-2.8c0-0.1,0-0.2,0-0.2
              c0-0.1,0.1-0.1,0.2-0.2l4.8-2.4c0.1,0,0.1,0,0.2,0h1.7c0.2,0,0.4,0.2,0.4,0.4L31.9,49.1z M35,13.1h-4.2v2c0,2.1,5,4.3,1.7,4.3
              c-3.3,0-4.5-2.2-4.5-4.3v-2H15.1v2c0,2.1-1.2,4.3-4.5,4.3c-3.3,0-4.5-2.2-4.5-4.3v-2H1.8C1.4,13.1,1,13.5,1,14v8.3
              C1,22.7,1.4,23,1.8,23H35c0.5,0,0.8-0.4,0.8-0.8V14C35.8,13.5,35.5,13.1,35,13.1z"/>
            <path style={styleBaseIconStyles} className="styleBase" d="M10.6,16.8c1.3,0,1.9-0.5,1.9-1.7v-3.9c0-1.2-0.5-1.7-1.9-1.7c-1.4,0-1.9,0.5-1.9,1.7v3.9
              C8.7,16.3,9.2,16.8,10.6,16.8z M32.5,16.8c1.3,0,1.9-0.5,1.9-1.7v-3.9c0-1.2-0.5-1.7-1.9-1.7c-1.4,0-1.9,0.5-1.9,1.7v3.9
              C30.6,16.3,31.1,16.8,32.5,16.8z"/>
            <path style={styleAccentIconStyles} className="styleAccent" d="M57,16.5c0,8.3-6.7,15-15,15c-2.4,0-4.7-0.6-6.7-1.6c-0.5-0.3-1.1-0.3-1.7-0.2l-3.3,0.9c-0.3,0.1-0.7,0.1-1,0
              c-0.3-0.1-0.6-0.3-0.9-0.5c-0.2-0.2-0.4-0.5-0.5-0.9c-0.1-0.3-0.1-0.7,0-1l0.9-3.3c0.1-0.6,0.1-1.2-0.2-1.7c-1-2.1-1.6-4.4-1.6-6.7
              c0-8.3,6.7-15,15-15S57,8.2,57,16.5z M42,9.4c0.3,0,0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8v0c1.6,0.4,3,1.7,3,3.5
              c0,0.3-0.1,0.6-0.3,0.8c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8c0-0.6-0.6-1.4-1.9-1.4
              s-1.9,0.8-1.9,1.4c0,0.6,0.6,1.4,1.9,1.4c2.1,0,4.1,1.4,4.1,3.6c0,1.8-1.4,3.1-3,3.5v0c0,0.3-0.1,0.6-0.3,0.8
              c-0.2,0.2-0.5,0.3-0.8,0.3s-0.6-0.1-0.8-0.3c-0.2-0.2-0.3-0.5-0.3-0.8v0c-1.6-0.4-3-1.7-3-3.5c0-0.3,0.1-0.6,0.3-0.8
              c0.2-0.2,0.5-0.3,0.8-0.3s0.6,0.1,0.8,0.3c0.2,0.2,0.3,0.5,0.3,0.8c0,0.6,0.6,1.4,1.9,1.4s1.9-0.8,1.9-1.4s-0.6-1.4-1.9-1.4
              c-2.1,0-4.1-1.4-4.1-3.6c0-1.8,1.4-3.1,3-3.5v0c0-0.3,0.1-0.6,0.3-0.8C41.4,9.5,41.7,9.4,42,9.4z"/>

        </svg>
      );
    }
  
  export default IcoCalendarDollar;