import ApproachImg from '../../images/staffwizard/approach.webp'
import Line from '../../images/staffwizard/line.webp';

const Approach = () => {
    return (
        <section className='approach-wrapper' >


                <div className="approach-content">
                    
                    <div className='approach-img'>
                        <img src={ApproachImg} alt="StaffWizard Approach" loading='lazy'/>
                    </div>

                    <div className='approach-text'>
                        <div className='approach-title'>
                        <h2>Our Approach</h2>
                        <div className='title-img'>
                            <img src={Line} alt="Black Line " loading='lazy'/>
                        </div>
                            
                        </div>
                        <p>When StaffWizard contacted Advante Digital in 2021, its platform was partially developed but needed expert intervention to reach its full potential. Our mission was to complete and refine the system, preparing it for launch to a broad customer base. One of our main challenges was to optimize the service for high-volume use, with tens of thousands of daily users. Our solution included increasing the server capacity and implementing sophisticated Cron services to cope with this demand.</p>
                    </div>
                    
                
            </div>
        </section>
    );
  }
  
  export default Approach;