
const DigitalSolution = () => {
    return (
        <section className='judigo-digital' >
            <div className="containerFull">
                <div className="judigo-digital-wrapper">        
                    <div className="judigo-digital-content">
                        <h2>Advante Digital's Trailblazing Solution for <span>Legal Empowerment</span></h2>                         
                        <p>Advante Digital's solution for the legal firm in California is a testament to our ability to deliver innovative, user-centric platforms under demanding timelines. Our expertise in modern web technologies and our forward-thinking approach have enabled us to create a platform that not only meets the current needs of the legal industry but is also poised for future growth and evolution. This project underscores our commitment to leveraging technology to make professional services more accessible and efficient.</p>
                    </div>
                    
                </div>
                    
                
            </div>
        </section>
    );
  }
  
  export default DigitalSolution;