import PhoneApp1 from '../../images/bridgewater/phone-app1.webp'
import PhoneApp2 from '../../images/bridgewater/phone-app2.webp'

const Development = () => {
    return (
        <>
            <section className='bridgewater-development' >
                <div className="containerFull">
                    <div className="development-container">
                        <h3>Development Process and Testing</h3>
                        <p>The development process took just over a month, followed by several weeks of meticulous testing. We ensured the site looked perfect and performed flawlessly across all devices and browsers, providing a consistent experience for every customer.</p>
                    </div>
                </div>
                    
            </section>

            <section className='bridgewater-solution' >
                <div className="containerFull">
                    <div className="bridge-solution-container">
                        <div className="bridge-solution-img">
                            <div>
                                <img src={PhoneApp1} alt="Bridgewater Chocolate Phone App" loading='lazy'/>
                            </div>
                            <div>
                                <img src={PhoneApp2} alt="Bridgewater Chocolate Phone App" loading='lazy' />
                            </div>
                        </div>
                        <div className="bridge-solution-text">
                            <h3>Our Final Solution</h3>
                            <p>Advante Digital's Shopify solution for Bridgewater Chocolate is a testament to our ability to create premium, elegant digital retail experiences. Our collaboration with Sixplusone resulted in a website that truly represents the quality and sophistication of Bridgewater Chocolate, offering an inviting and seamless shopping experience. This project showcases our commitment to delivering exceptional digital solutions that not only look beautiful but also drive business success and customer satisfaction.</p>
                        </div>
                            
                    </div>
                </div>
                    
            </section>
        </>
            
    );
}
    
export default Development;
