import '../components/GraphicDesign/graphic-design.css'
import Header from "../components/GraphicDesign/Header";
import OurDesignLaws from "../components/GraphicDesign/OurDesignLaws"
import Services from '../components/GraphicDesign/Services';
import IndustriesWeWork from '../components/GraphicDesign/IndustriesWeWork';

import TransitionPage from '../utils/TransitionPage';

import { Helmet } from 'react-helmet-async';

const GraphicDesign = () => {
  return (
    <>
        <Helmet>
            <title>Graphic Design</title>
            <meta name="description" content="Give a new point of view to your image. Let us help you give it a more dynamic, captivating and vibrant image so that everyone stops to see you. "/>
            <meta name='keywords' content='Creative Design, Branding, Visual Identity, Graphic Art, Design Services'/>  
        </Helmet>
        <Header />
        <Services />
        <OurDesignLaws />
        <IndustriesWeWork />
    </>
  );
}

export default TransitionPage (GraphicDesign);