function IcoSoftwareDevelopment({ color = '#000000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.5 151.5" fill={color}>
      <path d="M64.5,126.9c-0.4,0-0.7,0-1.4,0c-2.8-0.7-4.6-3.5-3.9-6.3l22.6-91.6c0.7-2.8,3.5-4.6,6.3-3.9c2.8,0.7,4.6,3.5,3.9,6.3
	L69.4,123C69.1,125.1,67,126.9,64.5,126.9z M104.3,104c-1.4,0-2.8-0.4-3.9-1.4c-2.1-2.1-2.1-5.3,0-7.4l19-19l-19-19
	c-2.1-2.1-2.1-5.3,0-7.4s5.3-2.1,7.4,0l22.6,22.9c2.1,2.1,2.1,5.3,0,7.4l-22.6,22.9C106.8,103.6,105.4,104,104.3,104z M47.2,104
	c-1.4,0-2.8-0.4-3.9-1.4L20.8,79.6c-2.1-2.1-2.1-5.3,0-7.4l22.6-22.9c2.1-2.1,5.3-2.1,7.4,0c2.1,2.1,2.1,5.3,0,7.4l-19,19l19,19
	c2.1,2.1,2.1,5.3,0,7.4C50,103.6,48.6,104,47.2,104z"/>
    </svg>
    
  );
}




export default IcoSoftwareDevelopment;