import SolutionsImg from '../../images/staffwizard/staff-solutions.webp';

const Solutions = () => {
    return (
        <section className='solutions' >

            <div className="containerFull">

                <div className="solutions-content">
                    <div className="improvement-title">
                        <h2>Elevating Security Solutions: The Exemplary Partnership of <br /><span>Advante Digital and StaffWizard.</span></h2>
                        <p>Advante Digital's partnership with StaffWizard exemplifies our commitment to delivering exceptional software solutions. Our expertise in handling large-scale, complex systems has transformed StaffWizard into a leading platform in the security industry. We are dedicated to maintaining and advancing this platform, fostering its growth and ensuring it remains at the forefront of technological excellence.</p>
                    </div>
                    <div className="solutions-img">
                        <img src={SolutionsImg} alt="Staffwizzard Solutions" loading='lazy'/>
                    </div>
                </div>
                
            </div>
        </section>
    );
  }
  
  export default Solutions;