import '../components/AllNation/all-nation.css'
import Header from "../components/AllNation/Header"
import Challenge from '../components/AllNation/Challenge'
import Deployment from '../components/AllNation/Deployment'
import ContactSection from '../components/AllNation/ContactSection'

import { Helmet } from 'react-helmet-async';

const AllNation = () => {
    return (
        <div>
            <Helmet>
                <title>Success case: All Nation Security</title>
                <meta name="description" content="Empowering All Nation Security: Launching a Cutting-Edge Employee Tracking System."/>
                <meta name='keywords' content='All Nation Security, Security Services, Tech Integration, Business Case, Digital Security'/>  
            </Helmet>
            <Header />
            <Challenge />
            <Deployment />
            <ContactSection/>
        </div>
    )
}

export default AllNation