import { Link } from "react-router-dom";

import IcoArrowThin from "../../images/svg/IcoArrowThin";

const JoinForces = () => {
  return (
    <>
      <section className="about-join">
        <div className="containerFull">
          <h3>Excited to join forces with us?</h3>
          <Link to={`/contact-us`} className='see-more-btn'>
              <span>Join Us</span> 
              <IcoArrowThin color="var(--midnight-blue)" />
          </Link>
        </div>
      </section>

    </>
  );
};

export default JoinForces;
