import '../components/MarketAnalysis/market-analysis.css'
import Header from "../components/MarketAnalysis/Header";
import Services from '../components/MarketAnalysis/Services';
import EmpoweringSolution from '../components/MarketAnalysis/EmpoweringSolution';
import ChooseIndustry from '../components/MarketAnalysis/ChooseIndustry';

import TransitionPage from '../utils/TransitionPage';

import { Helmet } from 'react-helmet-async';

const MarketAnalysis = () => { 
    return(
        <>
            <Helmet>
                <title>Market analysis</title>
                <meta name="description" content="A comprehensive market analysis is the compass that guides successful decision-making, revealing the intricate details of the market's heartbeat. "/>
                <meta name='keywords' content='Market Research, Industry Analysis, Business Insights, Market Trends, Competitive Analysis'/>  
            </Helmet>
            <Header />
            <Services />
            <EmpoweringSolution />
            <ChooseIndustry />
        </>
    );
}

export default  TransitionPage (MarketAnalysis);