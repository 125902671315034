import AllNationApp from '../../images/all-nation/all-nation-app.webp'
import Phone from '../../images/all-nation/all-nation-phone.webp'
import AppSlides from '../../images/all-nation/app-slides.webp'
const Challenge = () => {
    return (
        <>
            <section className='challenge-wrapper'>
                <div className='containerFull'>
                    <div className='challenge-header'>
                        <div className='challenge-text'>
                            <h2><span>The Challenge:</span> Revitalizing an Incomplete System.</h2>
                            <p>When Advante Digital was approached, All Nation Security's system was in a state of incompletion, with critical functionalities missing. Our task was not only to complete the existing framework but also to enhance its capabilities significantly.
                                The platform was initially developed with a React Native application and a PHP-based backend, a combination that promised robustness but required expert handling to reach its full potential.</p>
                        </div>
                        <div className='challenge-img'>
                            <img src={AllNationApp} alt="All Nation App" loading='lazy'/>
                        </div>
                    </div>
                </div>
                <div className='phone'>
                    <img src={Phone} alt="All Nation Security Phone App" loading='lazy'/>
                </div>
            </section>
            <section className='platform-wrapper'>
                <div className='containerFull'>
                    <div className='platform-text'>
                        <h2>A Comprehensive, User-Centric Platform.</h2>
                        <p>Our team focused on developing new features to manage complex aspects such as overtime, breaks, and various legal and payroll requirements. We refined the React Native application for optimal performance and user experience, ensuring that it met the specific needs of supervisors, managers, and agents. The PHP backend was overhauled to support these new features, providing a solid foundation for the platform's operations.</p>
                    </div>
                    <div className='platform-img'>
                        <img src={AppSlides} alt="All Nation Security Interfaces" loading='lazy' />
                    </div>
                </div>

            </section>
            <section className='bg-code'>
            </section>
        </>
        
            
        );
};
    
export default Challenge;
