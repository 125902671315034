import logo from '../images/logo_advante.webp'
import { Link, useLocation } from 'react-router-dom';
import { useState } from 'react';
import menuBar from '../images/menu-advante.svg'
import closeMenu from '../images/close.svg'

import navBarList from '../jsons/nav-bar.json'

import ScrollToNav from './ScrollToNav';

//import MenuIcon from '@mui/icons-material/Menu';

const Navbar = () => {

  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const [subMenuClass, setSubMenuClass] = useState('navBarList-child');

  const isPageActive = (pageLink) => {
    return location.pathname === pageLink || location.pathname.startsWith(pageLink);
  };

  const openMenu = () => {
    setMenuOpen(true);
  }

  const closeMenuF = () => {
    setMenuOpen(false);
    setSubMenuClass('navBarList-child');
  }

  const handleSubMenu = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth > 900) return;
    if (subMenuClass === 'navBarList-child') {
      setSubMenuClass('submenu');
    } else {
      setSubMenuClass('navBarList-child');
    }
  }

  return (
    <header className='nav-menu'>
      <nav className='containerFull'>
          <ScrollToNav />
            <Link to="/#hero"  className='navBarLogo'><img src={logo} alt="Logo Advante" /></Link>
            <ul className={`navBarList ${menuOpen ? 'open' : ''}`}>
              {
                navBarList.map( (item) => (
                  <li key={item.id} className={isPageActive(item.link) ? 'active' : ''}
                    onClick={item.page == 'Services' ? () => handleSubMenu() : null}>
                    {item.children ? (
                      <>
                        <span>{item.page} </span>
                        <ul className={subMenuClass}>
                          {
                            item.children.map((children) => (
                              <li key={children.id} className={isPageActive(item.link + children.link) ? 'active' : ''}>
                                <Link to={item.link + children.link} onClick={ closeMenuF } >{children.page}</Link>
                              </li>
                            ))
                          }
                        </ul>
                      </>
                    ) : (
                      <Link to={item.link} onClick={ closeMenuF } >{item.page}</Link>
                    )}
                  </li>
                ))
              }

            </ul>
            <div className='menu-bar-wrapper'>
              <img 
                src={ menuOpen ? closeMenu : menuBar }
                alt='menu'
                onClick={ () => { menuOpen ? closeMenuF() : openMenu() } }
              />
            </div>
        </nav>
    </header>
  );
}

export default Navbar;