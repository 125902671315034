import Gradient from '../../images/all-nation/all-nation-gradient.webp'

const Header = () => {
    return (
        <section className='nation-header' >
            <div className="bgr-blur"></div>
            <div className='gradient-wrapper'>
                 <div className="g1">
                    <img src={Gradient} alt="Gradient" loading='lazy'/>
                </div>
                <div className="containerFull">
                    
                
                    <div className="nation-header-wrapper">        
                        <div className="nation-header-title">
                            <h1>All Nation Security</h1>
                            <h2>Empowering All Nation Security: Launching a Cutting-Edge Employee Tracking System</h2>
                            <p>All Nation Security, a prominent Californian security firm, faced a challenge with an unfinished employee tracking platform, crucial for managing their field staff. Our role was pivotal in transforming this challenge into a success. We not only completed the development of this vital GPS-enabled mobile app but also successfully launched it. This case study showcases our journey in enhancing All Nation Security’s operational efficiency and personnel management through technological innovation.</p>                              
                        </div>
                        
                        
                    </div>
                        
                    
                </div>
            </div>
        </section>
    );
}
    
export default Header;
