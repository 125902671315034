
const HeaderText = () => {
    return (
        <section className='header-text' >

            <div className="containerFull">

                <div className="header-content">
                    <p>StaffWizard, a pioneering platform in the security industry, offers an innovative solution for security companies. This robust system streamlines the onboarding process, tracks security personnel, manages shift assignments, and handles complex payroll invoicing with ease. The platform's mobile app enables security guards to seamlessly manage their work schedules, from checking in for shifts to taking breaks and checking out. What sets StaffWizard apart is its adaptability to intricate labor regulations across multiple states in the U.S., ensuring compliance with varied overtime rules and other payroll complexities.</p>
                </div>
                
            </div>
        </section>
    );
  }
  
  export default HeaderText;