import FeaturesImg1 from '../../images/seagrams/features1.webp';
import FeaturesImg2 from '../../images/seagrams/features2.webp';
import OutcomeApp from '../../images/seagrams/outcome-app.webp';
import DevWaves from '../../images/seagrams/development-wave.webp';

const Features = () => {
    return (
        <>
            <section className="seagrams-features-wrapper">
                <div className="containerFull">
                    <h2>Key Features of the website</h2>
                    <div className="feature-group">
                        <div className="seagrams-feature__img">
                            <img src={FeaturesImg1} alt="Seagrams Escapes Features" loading='lazy'/>
                        </div>
                        <div className="seagrams-feature__text">
                            <ul>
                                <li>
                                    Fluid Animations:
                                    <span>The website features realistic liquid animations and bubbling effects, reflecting the refreshing nature of Seagram's beverages.</span>
                                </li>
                                <li>
                                    Individual Product Showcases:
                                    <span> Each flavor and product line is presented with its unique design and animation, making every visit to the site a new discovery.</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="feature-group">
                        <div className="seagrams-feature__text">
                            <ul className='second-list'>
                                <li>
                                    Interactive Curtain Reveat:
                                    <span>A creative curtain animation entices users to explore different product lines, enhancing the interactive experience.</span>
                                </li>
                                <li>
                                    Cocktail Recepies Section:
                                    <span> The site includes a range of cocktail recipes, each with a unique design and layout, encouraging users to try Seagram's beverages in new and exciting ways.</span>
                                </li>
                                <li>
                                    Store Locator Integration:
                                    <span> Integrated with Destini, the website helps consumers easily find nearby stores carrying Seagram's products, ensuring a seamless transition from virtual exploration to physical purchase.</span>
                                </li>
                            </ul>
                        </div>
                        <div className="seagrams-feature__img">
                            <img src={FeaturesImg2} alt="Seagrams Escapes Features" loading='lazy' />
                        </div>
                    </div>
                </div>
            </section>

            <section className='seagrams-development-wrapper'>
                <img src={DevWaves} alt="SVG Blue Waves" className='dev-waves' loading='lazy'/>
                <div className="containerFull">
                    <div className="seagrams-development-process">
                        <h2>How was the development and testing process?</h2>
                        <p>The development of Seagram's Escapes website was an intricate process, focusing on detailed animations and user interactivity. Rigorous testing was conducted to ensure that all animations were smooth and that the site's functionality was consistent across different devices and browsers.</p>
                    </div>

                    <div className='seagrams-outcome'>
                        <div className="seagrams-outcome__text">
                            <h2>Our Outcome</h2>
                            <p>Advante Digital's website for Seagram's Escapes is a testament to our ability to create visually stunning and engaging digital experiences. The animated, fluid design of the site perfectly embodies the essence of Seagram's beverages, offering a delightful and interactive way for consumers to explore and connect with the brand. This project highlights our expertise in innovative web design and our commitment to providing immersive, user-centric solutions.</p>
                        </div>
                        <div className="seagrams-outcome__img">
                            <img src={OutcomeApp} alt="Seagrams Escapes Outcome" loading='lazy' />
                        </div>
                    </div>
                        
                </div>
            </section>

        </>
            
    )
}

export default Features;