import '../components/CaseStudies/case-studies.css'
import Header from '../components/CaseStudies/Header';
import CaseStudiesList from '../components/CaseStudies/CaseStudiesList';
import Testimonials from '../components/Testimonials';

import TransitionPage from '../utils/TransitionPage';
import { Helmet } from 'react-helmet-async';

const CaseStudies = () => {
    return (
        <>
            <Helmet>
                <title>Case Studies</title>
                <meta name="description" content="Explore our case studies: Hundreds of apps & websites successfully delivered across the US in 16 years, showcasing our diverse client success stories."/>
                <meta name='keywords' content='software cases, case studies, digital projects, client testimonials, project portfolio'/>  
            </Helmet>
            <Header />
            <CaseStudiesList />
            <Testimonials />
        </>
        
    );
  }
  
  export default TransitionPage( CaseStudies );