import '../components/JudigoLegal/judigo-legal.css'
import Header from "../components/JudigoLegal/Header"
import Solution from '../components/JudigoLegal/Solution';
import Features from '../components/JudigoLegal/Features';
import Technologies from '../components/JudigoLegal/Technologies';
import DigitalSolution from '../components/JudigoLegal/DigitalSolution';
import ContactSection from '../components/JudigoLegal/ContactSection';

import { Helmet } from 'react-helmet-async';

const JudigoLegal = () => {
    return (
        <>
            <Helmet>
                <title>Success case: JudigoLegal </title>
                <meta name="description" content="Revolutionizing legal services for all, through a custom built platform that connects consumers with laywers. "/>
                <meta name='keywords' content='JudigoLegal Case Study, Legal Tech, Case Study, Software Success, Digital Transformation'/>  
            </Helmet>
            <Header />
            <Solution />
            <Features />
            <Technologies />
            <DigitalSolution />
            <ContactSection />
        </>
        
    );
};
    
export default JudigoLegal;