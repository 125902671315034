function IcoCheckoutLocation({ baseColor = '#000000', accentColor ='var(--sky-blue)' }) {

    const styleBaseIconStyles = {
        fill: baseColor, 
    };
    const styleAccentIconStyles = {
        fill: accentColor, 
    };

      return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 58 58">
            <path style={styleBaseIconStyles} className="styleBase" d="M9.8,11.8c-1.6,0-2.9,1.3-2.9,2.9v5.8c0,1.6,1.3,2.9,2.9,2.9H17v2.9h-5.1c-2.8,0-5.3,2.1-5.7,4.9l-2,13.4
              c-0.1,0.4-0.1,0.9-0.1,1.3v6.3c0,3.2,2.6,5.8,5.8,5.8h34.5c3.2,0,5.8-2.6,5.8-5.8v-6.3c0-0.4,0-0.9-0.1-1.3l-2-13.4
              c-0.4-2.8-2.8-4.9-5.7-4.9H22.8v-2.9h7.2c1.6,0,2.9-1.3,2.9-2.9v-5.8c0-1.6-1.3-2.9-2.9-2.9H9.8z M12.7,16.2h14.4
              c0.8,0,1.4,0.6,1.4,1.4S27.9,19,27.1,19H12.7c-0.8,0-1.4-0.6-1.4-1.4S11.9,16.2,12.7,16.2z M9.8,50.7c0-0.8,0.6-1.4,1.4-1.4h31.6
              c0.8,0,1.4,0.6,1.4,1.4s-0.6,1.4-1.4,1.4H11.3C10.5,52.1,9.8,51.4,9.8,50.7z M14.1,35.6c-0.6,0-1.1-0.2-1.5-0.6
              c-0.4-0.4-0.6-1-0.6-1.5s0.2-1.1,0.6-1.5c0.4-0.4,1-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6c0.4,0.4,0.6,1,0.6,1.5s-0.2,1.1-0.6,1.5
              S14.7,35.6,14.1,35.6z M24.9,33.4c0,0.6-0.2,1.1-0.6,1.5s-1,0.6-1.5,0.6s-1.1-0.2-1.5-0.6s-0.6-1-0.6-1.5c0-0.6,0.2-1.1,0.6-1.5
              c0.4-0.4,1-0.6,1.5-0.6s1.1,0.2,1.5,0.6C24.7,32.3,24.9,32.8,24.9,33.4z M18.4,42.7c-0.6,0-1.1-0.2-1.5-0.6s-0.6-1-0.6-1.5
              s0.2-1.1,0.6-1.5s1-0.6,1.5-0.6c0.6,0,1.1,0.2,1.5,0.6s0.6,1,0.6,1.5s-0.2,1.1-0.6,1.5S19,42.7,18.4,42.7z M33.5,33.4
              c0,0.6-0.2,1.1-0.6,1.5s-1,0.6-1.5,0.6s-1.1-0.2-1.5-0.6s-0.6-1-0.6-1.5s0.2-1.1,0.6-1.5c0.4-0.4,1-0.6,1.5-0.6s1.1,0.2,1.5,0.6
              C33.3,32.3,33.5,32.8,33.5,33.4z M27.1,42.7c-0.6,0-1.1-0.2-1.5-0.6s-0.6-1-0.6-1.5s0.2-1.1,0.6-1.5s1-0.6,1.5-0.6s1.1,0.2,1.5,0.6
              s0.6,1,0.6,1.5s-0.2,1.1-0.6,1.5S27.6,42.7,27.1,42.7z M42.2,33.4c0,0.3-0.1,0.6-0.2,0.8c-0.1,0.3-0.3,0.5-0.5,0.7s-0.4,0.4-0.7,0.5
              c-0.3,0.1-0.5,0.2-0.8,0.2s-0.6-0.1-0.8-0.2c-0.3-0.1-0.5-0.3-0.7-0.5s-0.4-0.4-0.5-0.7c-0.1-0.3-0.2-0.5-0.2-0.8s0.1-0.6,0.2-0.8
              c0.1-0.3,0.3-0.5,0.5-0.7c0.2-0.2,0.4-0.4,0.7-0.5c0.3-0.1,0.5-0.2,0.8-0.2s0.6,0.1,0.8,0.2c0.3,0.1,0.5,0.3,0.7,0.5
              c0.2,0.2,0.4,0.4,0.5,0.7C42.1,32.8,42.2,33.1,42.2,33.4z M35.7,42.7c-0.6,0-1.1-0.2-1.5-0.6s-0.6-1-0.6-1.5s0.2-1.1,0.6-1.5
              s1-0.6,1.5-0.6s1.1,0.2,1.5,0.6s0.6,1,0.6,1.5s-0.2,1.1-0.6,1.5S36.3,42.7,35.7,42.7z"/>
            <path style={styleAccentIconStyles} className="styleAccent" d="M44,0.2c-2.4,0-4.6,0.9-6.3,2.6C36,4.4,35.1,6.6,35.1,9c0,1.9,0.6,3.8,1.8,5.3c0,0,0.2,0.3,0.3,0.4l6.8,8l6.8-8
              c0,0,0.3-0.4,0.3-0.4l0,0c1.2-1.5,1.8-3.4,1.8-5.3c0-2.3-0.9-4.6-2.6-6.2C48.6,1.1,46.3,0.2,44,0.2z M44,12.2
              c-0.6,0-1.3-0.2-1.8-0.5c-0.5-0.4-0.9-0.9-1.2-1.4c-0.2-0.6-0.3-1.2-0.2-1.9c0.1-0.6,0.4-1.2,0.9-1.6c0.5-0.4,1-0.8,1.7-0.9
              C44,5.7,44.6,5.8,45.2,6c0.6,0.2,1.1,0.7,1.5,1.2C47,7.7,47.2,8.4,47.2,9c0,0.9-0.3,1.7-0.9,2.3C45.7,11.9,44.8,12.2,44,12.2z"/>
        </svg>
      );
    }
  
  export default IcoCheckoutLocation;