function IcoPlatformIntegrations({ color = '#000000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.5 151.5" fill={color}>
      <path d="M39.8,35.4H3.2c-2.4,0-3.3-1.8-3.3-3V31h3.6V10.6C3.6,9.7,4,9,4.6,8.4c0.6-0.5,1.4-0.8,2.2-0.8h29.2c0.9,0,1.6,0.3,2.2,0.8
	c0.7,0.6,1.1,1.3,1.1,2.2v20.3h3.6v1.4C43,33.6,42.2,35.4,39.8,35.4z M6.5,29.4h30.1V10.6c-0.1-0.1-0.2-0.1-0.4-0.1H6.8
	c-0.2,0-0.3,0.1-0.4,0.1L6.5,29.4z"/>
    </svg>
    
  );
}




export default IcoPlatformIntegrations;