import '../components/PaymentSolutions/payment-solutions.css'
import ExpertiseServices from "../components/PaymentSolutions/ExpertiseServices";
import Header from "../components/PaymentSolutions/Header"
import TechnologyStack from "../components/PaymentSolutions/TechnologyStack";
import MarketsWeServe from "../components/PaymentSolutions/MarketsWeServe"

import TransitionPage from '../utils/TransitionPage';
import { Helmet } from 'react-helmet-async';

const PaymentSolutions = () => {
  return (
    <>
      <Helmet>
          <title>Payment Solutions</title>
          <meta name="description" content="We believe that the ease of payment is one of the important issues for customers to look for you, and stay with you. Integrate your apps with Stripe, PayPal, Affirm, and many more."/>
          <meta name='keywords' content='Payment Systems, E-commerce Payments, Financial Technology, Secure Transactions, Online Payments, Stripe integration, PayPal integration, Google wallet integration, Apple wallet integration'/>
      </Helmet>
      <Header />
      <TechnologyStack />
      <ExpertiseServices />
      <MarketsWeServe />
    </>
  );
}

export default TransitionPage(PaymentSolutions ); 