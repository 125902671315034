import React from 'react';

function IcoArrowThin({ color = '#000000'}) {

const iconStyles = {
    fill: color, // Usar el valor de la variable fill
};

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 10" style={iconStyles} >
      <path d="M7.73647 0.266472C7.9106 0.0958501 8.14675 0 8.39298 0C8.6392 0 8.87535 0.0958501 9.04949 0.266472L13.2281 4.36204C13.4022 4.53271 13.5 4.76416 13.5 5.00549C13.5 5.24683 13.4022 5.47828 13.2281 5.64895L9.04949 9.74452C8.87435 9.9103 8.63979 10.002 8.39632 9.99996C8.15285 9.99789 7.91995 9.90217 7.74778 9.73343C7.57561 9.56469 7.47795 9.33641 7.47584 9.09778C7.47372 8.85915 7.56732 8.62925 7.73647 8.4576L10.2501 5.91562H1.42859C1.18231 5.91562 0.94612 5.81973 0.771976 5.64905C0.597833 5.47837 0.5 5.24687 0.5 5.00549C0.5 4.76411 0.597833 4.53262 0.771976 4.36194C0.94612 4.19126 1.18231 4.09537 1.42859 4.09537H10.2501L7.73647 1.55339C7.56238 1.38272 7.46459 1.15126 7.46459 0.909931C7.46459 0.668598 7.56238 0.437146 7.73647 0.266472Z" />
    </svg>
    
  );
}

export default IcoArrowThin;