function IcoEcommerce({ color = '#000000'}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 151.5 151.5" fill={color}>
      <path d="M39.8,16.5L36.9,6.3c-0.2-0.5-0.5-1-0.9-1.3c-0.5-0.3-1-0.5-1.6-0.5h-5.6h-3H17h-3H8.5C7.9,4.5,7.4,4.7,6.9,5
	C6.5,5.3,6.2,5.8,6,6.3L3.1,16.5C3,16.8,3,17.2,3,17.6c0.1,0.4,0.2,0.7,0.4,1c0.2,0.3,0.5,0.6,0.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2v14.1
	c0,1.3,0.5,2.6,1.4,3.5c0.9,0.9,2.2,1.4,3.5,1.4h24.1c1.3,0,2.6-0.5,3.5-1.4s1.4-2.2,1.4-3.5V19.5c0.1,0,0.2-0.1,0.3-0.1
	c0.3-0.2,0.6-0.4,0.8-0.8c0.2-0.3,0.3-0.6,0.4-1C39.9,17.2,39.9,16.9,39.8,16.5z M34.1,7.5l2.7,9.3c-1.9,0.2-3.5,0.1-4.5-0.2
	c-0.6-0.2-1.5-0.6-2.5-1.2l-0.7-7.9H34.1z M16.8,7.5h9.3l0.7,8c-0.9,0.5-1.8,0.9-2.3,1.1c-2.3,0.7-3.7,0.7-6,0
	c-0.6-0.2-1.4-0.6-2.3-1.1l0.2-2.7L16.8,7.5z M8.8,7.5h5l-0.7,7.9c-1,0.6-1.9,1-2.5,1.2c-1,0.3-2.6,0.4-4.5,0.2L8.8,7.5z M24.8,35.5
	h-7.3v-8.8c0-0.5,0.2-1,0.6-1.4s0.9-0.6,1.4-0.6h3.4c0.5,0,1,0.2,1.4,0.6s0.6,0.9,0.6,1.4v8.8H24.8z M34.9,34.9
	c-0.4,0.4-0.9,0.6-1.4,0.6h-5.7v-8.8c0-1.3-0.5-2.6-1.4-3.5s-2.2-1.4-3.5-1.4h-3.4c-1.3,0-2.6,0.5-3.5,1.4s-1.4,2.2-1.4,3.5v8.8H9.4
	c-0.5,0-1-0.2-1.4-0.6c-0.3-0.3-0.5-0.8-0.5-1.3V19.9c0.2,0,0.5,0,0.7,0c1.2,0,2.4-0.1,3.4-0.4s2.2-1,3-1.4c0.9,0.5,2,1.1,3,1.4
	c1.4,0.4,2.7,0.7,3.9,0.7c1.2,0,2.5-0.2,3.9-0.7c1-0.3,2.1-0.9,3-1.4c0.9,0.5,2,1.1,3,1.4c1,0.3,2.2,0.4,3.4,0.4c0.2,0,0.5,0,0.7,0
	v13.7C35.5,34.1,35.2,34.6,34.9,34.9z"/>
    </svg>
    
  );
}

export default IcoEcommerce;