import '../components/Rowdy/rodwy.css'

import Header from '../components/Rowdy/Header.jsx';
import RodwyFeatures from '../components/Rowdy/Features.jsx';
import ContactSection from '../components/Rowdy/ContactSection.jsx';

import { Helmet } from 'react-helmet-async';

const RowdyEnergy = () => {
    return (
        <>
            <Helmet>
                <title>Success case: Rowdy Energy Drink</title>
                <meta name="description" content="Learn how we developed a robust and beautiful Shopify site that breaks away from conventional designs and embraces a modern approach."/>
                <meta name='keywords' content='Rowdy Energy Drink, Beverage Industry, Marketing Success, Brand Development, Digital Strategy'/>
            </Helmet>
            <Header />
            <RodwyFeatures />
            <ContactSection />
        </>
    );
}
    
export default RowdyEnergy;