import Landing from '../../images/rowdy-energy/rowdy-landing.webp'
import RedCircle from '../../images/rowdy-energy/challenge-ellipse.webp'
import GetRowdy from '../../images/rowdy-energy/get-rowdy.webp'
import RowdySolution from '../../images/rowdy-energy/rowdy-solution.webp'

const Header = () => {
    return (
        <>
            <section className="rowdy-header-wrapper">
                <div className="orange-bg"></div>
                <div className="containerFull">
                    <div className="rowdy-header__title">
                        <h1>Rowdy Energy Drink</h1>
                        <h2>Unleash energy, unleash Rowdy!</h2>
                        <p>Rowdy Energy Drink, a dynamic company in the U.S. energy drink market, sought to revamp their online presence with a new, animated corporate website. The goal was to break away from conventional designs and embrace a modern, lively approach that reflected the brand's energy and ethos.</p>
                    </div>
                    <div className="rowdy-header__img">
                        <img src={Landing} alt="Rowdy Energy Drinks Landing Page" loading='lazy' />
                    </div>
                </div>
            </section>

            <section className="rowdy-challenge-wrapper">
                <div className='red-circle'>
                    <img src={RedCircle} alt="" />
                </div>
                <div className="rowdy-challenge__text">
                    <h2>The Challenge: </h2>
                    <span>A unique and animated design</span>
                    <p>Our challenge was to bring to life an entirely unique design concept, provided by Sixplusone, a New York-based design agency. The design was unlike anything previously seen in the industry, requiring us to innovate and push the boundaries of web development. Our task was to program and animate each section of the website, ensuring that the final product was as dynamic and engaging as the designs envisioned.</p>
                </div>
                <div className="rowdy-challenge__img">
                    <img src={GetRowdy} alt="Rowdy Energy Drinks Challenges" loading='lazy' />
                </div>
            </section>

            <section className="rowdy-solution-wrapper">
                <div className='containerFull'>
                    <div className="rowdy-solution__text">
                        <h2>Our Solution: </h2>
                        <span>A cutting-edge responsive website</span>
                        <p>Advante Digital developed a website that stood out for its intricate animations and interactivity. The main pages – the homepage, shop, locate, learn more, and gear – featured advanced parallax and carousel animations, creating an immersive user experience.</p>
                    </div>
                    <div className="rowdy-solution__img">
                        <img src={RowdySolution} alt="Rowdy Energy Drinks Solution" loading='lazy'/>
                    </div>
                </div>
                    
            </section>
        </>
            
    );
}
    
export default Header;