
import Grad1 from "../../images/judigo-legal/grad1.webp";
import Grad2 from "../../images/judigo-legal/grad2.webp";
import Title from "../../images/judigo-legal/title.webp";
import JudigoPhone from "../../images/judigo-legal/judigo-phone.webp";

const Header = () => {
    return (
        <section className='judigo-header' >
            <div className="bgr-noise"></div>
            <div className="containerFull">
                
                <div className="g1">
                    <img src={Grad1} alt="Gradient" loading="lazy" />
                </div>
                <div className="g2">
                    <img src={Grad2} alt="Gradient" loading="lazy" />
                </div>
                <div className="judigo-header-wrapper">        
                    <div className="judigo-header-title">
                        <img src={Title} alt="" />
                        <h2>Revolutionizing Legal Services for All</h2>
                        <div className="img-wrapper">
                            <p>A prestigious legal firm in California envisioned a revolutionary platform to democratize access to legal services. Their goal was twofold: to make it easier and more affordable for individuals to connect with lawyers, and to streamline the process for attorneys and law firms to acquire new clients. This platform was designed to be a game-changer in the legal sector, breaking down barriers and simplifying the process of finding legal assistance.</p>
                            <div className="judigo-img">
                                <img src={JudigoPhone} alt="Judigo Legal App" loading="lazy"/>
                            </div>
                        </div>
                            
                    </div>
                    
                    
                </div>
                    
                
            </div>
        </section>
    );
  }
  
  export default Header;