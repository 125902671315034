import { useState } from "react";
import jsonp from "jsonp";

const Newsletter = () => {

  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [state, setState] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    
    const url = "https://motionode.us17.list-manage.com/subscribe/post-json?u=408694affe6acfdd00fc655da&amp;id=1a3a510000&amp;f_id=00784de0f0"

    if (!email) {
      setState(false);
      setMessage('Please enter a valid email address.');
      setTimeout(() => {
        setMessage('');
        setState(true);
    }
    , 2000);
      return;
    }
    setMessage('Subscribing...');

    //JSONP request
    jsonp(`${url}&EMAIL=${email}`, { param: 'c' }, (_, data) => {
      const { msg, result } = data
      if (result ==  'success') {
        setMessage('Thanks for subscribing!');
        setTimeout(() => {
          setMessage('');
          setEmail('');
        }, 4000);

        return;
      } else {
        setState(false);
        setMessage('There was an error subscribing. Please try again.');
        setTimeout(() => {
          setMessage('');
          setState(true);
        }, 2000);
        return;
      }
      // do something with response
      alert(msg);
    
    
    })
  }
  
    

  return (
    <section className='newsletterWrapper'>
      <div className='containerFull'>
        <div>
          <h3>Subscribe to our newsletter</h3>
          <p>Stay in the loop about our new releases and unsubscribe whenever you like.</p>
        </div>
        <div className="newsletterForm">
          <form onSubmit={handleSubmit}>
            <input type="text" value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email" />
            <button type="submit">Subscribe</button>
          </form>
          <p className={'form-message'}>{ message}</p>
        </div>
      </div>
    </section>

  );
}

export default Newsletter;