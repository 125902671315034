import { Box, TextField, FormControlLabel, Checkbox } from "@mui/material";
import { useState } from "react";

const ContactUs = (props) => {
  const {
    headLineText,
    headLineSpan,
    wrapperBox,
    darkTheme,
    underline,
    h3Color,
    spanClass = "text-blue-violet-pink",
    sendColor = "var(--royal-purple)",
    checkColor = "var(--royal-purple)",
    bgCircles
  } = props;

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [check, setCheck] = useState(0);
  const [alertM, setAlertM] = useState('');
  const [status, setStatus] = useState(false);


    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (!name || !email || !subject || !message) {
            setAlertM('Please fill in all fields');
            return;
        } else {
            if(!email.includes('@')) {
                setAlertM('Please enter a valid email');
                return;
            } else {
          
                  if(message.length < 5) {
                      setAlertM('Message must be at least 10 characters');
                      return;
                  } else {
                      if(name.length < 3) {
                          setAlertM('Name must be at least 3 characters');
                          return;
                      } else {

                          sendData();

                          setAlertM('Message sent successfully!');
                          setStatus(true);
                          setName('');
                          setEmail('');
                          setMessage('');
                          setSubject('');
                          setTimeout(() => {
                              setAlertM('');
                              setStatus(false);
                          }, 3000);

                      }
                  
                    
                }
            }
        }
    }

    const sendData = async () => {

        const response = await fetch(
            `https://functions.advantedigital.com/contact.php?from=${email}&message=${message}&name=${name}&subject=${subject}`, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            
        });

        const resData = await response.json();

        if (!response.ok) {
            throw new Error(resData.message || 'Something went wrong!');
        } else {
            setAlertM('Message sent successfully!');
            setStatus(true);
            setName('');
            setEmail('');
            setMessage('');
            setSubject('');
            setTimeout(() => {
                     setAlertM('');
                     setStatus(false);
                }, 3000);
            
      }
      
    }

  return (
    <Box
      className="contactUsWrapper"
      component="form"
      noValidate
      autoComplete="off"
    >
      <div>
        <div className="contact-form-headline">
          <h3 style={h3Color && { color: h3Color }}>
            {headLineText && headLineSpan ? (
              <>
                {headLineText} <span className={spanClass}>{headLineSpan}</span>.
              </>
            ) : headLineText ? (
              <>{headLineText}.</>
            ) : headLineSpan ? (
              <span className={spanClass}>{headLineSpan}.</span>
            ) : (
              <>
                Contact <span className={spanClass}>Us</span>.
              </>
            )}
          </h3>

          {underline && (
            <div
              className="title-underline"
              style={{ background: underline }}
            ></div>
          )}
        </div>
        <div
          className="contact-form-wrapper"
          style={wrapperBox && { backgroundColor: "#FFF" }}
        >
          <TextField
            className="halfWidth"
            required
            id="name"
            label="Full Name"
            variant="outlined"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <TextField
            className="halfWidth"
            required
            id="email"
            label="Your Email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <TextField
            fullWidth
            required
            id="subject"
            label="Subject"
            variant="outlined"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />

          <TextField
            fullWidth
            required
            id="message"
            label="Message"
            multiline
            rows={6}
            variant="outlined"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />

          <FormControlLabel
            className="fullWidth"
            control={<Checkbox 
              sx={{
                color: checkColor ,
                '&.Mui-checked': {
                  color:  checkColor ,
                },
              }}
              checked={check}
              onChange={(e) => setCheck(e.target.checked ? 1 : 0)}
            />}
            label="I’d like to receive occasional news and updates from Advante Digital."
          />

          <p className={`alert-message ${ status ? 'success' : 'fail' }`}>{alertM}</p>

          <button
            className="baseButton baseButtonCentered"
            style={{ background: sendColor || "#FFF" }}
            type="submit"
            onClick={handleFormSubmit}
          >
            Send
          </button>
        </div>
      </div>

      {
        bgCircles && bgCircles.map(( circle, index ) => (
          <div 
            key={ index } 
            className = "bgCircle"
            style={{
              width : circle.size,
              height: circle.size,
              backgroundColor: circle.color,
              top: circle.top,
              left: circle.left,
              right: circle.right,
              opacity: circle.opacity
            }}
          />
        ) )
      }
    </Box>
  );
};

export default ContactUs;
