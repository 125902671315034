import { useState } from "react";
import { Link } from "react-router-dom";
import IcoArrowThin from "../../images/svg/IcoArrowThin";

import PlanningImg from '../../images/spa-live/planning-img.webp'
import DesignImg from '../../images/spa-live/design-img.webp'
import DevelopmentImg from '../../images/spa-live/development-img.webp'
import EvaluationImg from '../../images/spa-live/evaluation-img.webp'
import ImplementationImg from '../../images/spa-live/implementation-img.webp'

const ExpertiseServices = () => {

    const [activeExpertise, setActiveExpertise] = useState(1);

    const handleExpertiseClick = (itemNumber) => {
        setActiveExpertise(itemNumber);
    };


    return (
        <section className="expertise-services">
            <div className="containerFull expertise-services-wrapper">
                <div className="development-title">
                    <h3>Development Process</h3>
                    <p>followed a carefully planned methodology from idea conception to implementation and results</p>
                </div>
                
                <section className="process-grid">
                    <div className="process-item planning">
                        <div className="process-item-text">
                            <h4>1. PLANNING</h4>
                            <p>Definition of objectives and requirements. <br />
                                Establishment of a development schedule. </p>
                        </div>
                        <div className="process-item-image">
                            <img src={PlanningImg} alt="Spa Live Planning Process" loading="lazy"/>
                        </div>
                    </div>
                    <div className="process-item design">
                        <div className="process-item-text">
                            <h4>2. DESIGN AND ARCHITECTURE</h4>
                            <p>Prototyping and interface design. <br />
                            Application architecture development.</p>
                        </div>
                        <div className="process-item-image">
                            <img src={DesignImg} alt="Spa Live Design Process" loading="lazy" />
                        </div>
                    </div>
                    <div className="process-item development">
                        <div className="process-item-text">
                            <h4>3. ITERATIVE DEVELOPMENT</h4>
                            <p>Implementation of key modules in successive iterations. <br />
                            Continuous integration of user feedback and testing</p>
                        </div>
                        <div className="process-item-image">
                            <img src={DevelopmentImg} alt="Spa Live Development Process" loading="lazy" />
                        </div>
                    </div>
                    <div className="process-item evaluation">
                        <div className="process-item-text">
                            <h4>4. EVALUATION AND CONTINUOUS IMPROVEMENT</h4>
                            <p>Exhaustive testing of each functionality.<br />
                            Iterations based on results and feedback</p>
                        </div>
                        <div className="process-item-image">
                            <img src={EvaluationImg} alt="Spa Live Evaluation Process" loading="lazy" />
                        </div>
                    </div>
                    <div className="process-item implementation">
                        <div className="process-item-text">
                            <h4>5. POST IMPLEMENTATION ANALYSIS</h4>
                            <p>Evaluation of key metrics.<br />
                            Identification of areas of improvement and future expansion. <br />
                            This meticulous process ensured the delivery of a robust and functional application that meets the highest standards in the medical aesthetics industry.</p>
                        </div>
                        <div className="process-item-image">
                            <img src={ImplementationImg} alt="Spa Live Implementation Process" loading="lazy" />
                        </div>
                    </div>
                </section>
                <div className="like-process">
                    <h3>Do you like the process?</h3>
                    <p>Try it with us!</p>
                </div>
                <div className="button-wrapper">
                    <div className="process-button">
                        <Link to={`/contact-us`} className='thinButton'>
                            <span>Tell us about your project</span>
                            <IcoArrowThin color='var(--white-color)' />
                        </Link>
                    </div>
                    
                </div>
                
            </div>
        </section>
    );
  }
  
  export default ExpertiseServices;