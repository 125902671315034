import { BrowserRouter as Router } from 'react-router-dom';
import './styles/App.css'
import './styles/layout.css'
import Navbar from './components/Navbar'
import { HelmetProvider } from 'react-helmet-async';

import AnimatedRoutes from './utils/AnimatedRoutes';

import Footer from './components/Footer'

import { ThemeProvider } from '@mui/material/styles';
import theme from './styles/Theme'; 


const App = () => {

  const helmetContext = {};

  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <Router>
          
            <Navbar />
            <div className='main-wrapper' id='hero'>
              <AnimatedRoutes />
            </div>
            <Footer />
          
        </Router>
      </ThemeProvider> 
    </HelmetProvider >
  );
}; 

export default App; 
