const getIconPath = (type) => {
    switch (type) {
      case 'IcoSoftwareDevelopment':
        return (
          <path d="M18.3,36c-0.1,0-0.2,0-0.4,0c-0.8-0.2-1.3-1-1.1-1.8l6.4-26c0.2-0.8,1-1.3,1.8-1.1s1.3,1,1.1,1.8l-6.4,26
          C19.6,35.5,19,36,18.3,36z M29.6,29.5c-0.4,0-0.8-0.1-1.1-0.4c-0.6-0.6-0.6-1.5,0-2.1l5.4-5.4l-5.4-5.4c-0.6-0.6-0.6-1.5,0-2.1
          s1.5-0.6,2.1,0l6.4,6.5c0.6,0.6,0.6,1.5,0,2.1l-6.4,6.5C30.3,29.4,29.9,29.5,29.6,29.5z M13.4,29.5c-0.4,0-0.8-0.1-1.1-0.4l-6.4-6.5
          c-0.6-0.6-0.6-1.5,0-2.1l6.4-6.5c0.6-0.6,1.5-0.6,2.1,0s0.6,1.5,0,2.1L9,21.5l5.4,5.4c0.6,0.6,0.6,1.5,0,2.1
          C14.2,29.4,13.8,29.5,13.4,29.5z"/>
        );
      case 'IcoEcommerce':
        return (
          <path d="M39.8,16.5L36.9,6.3c-0.2-0.5-0.5-1-0.9-1.3c-0.5-0.3-1-0.5-1.6-0.5h-5.6h-3H17h-3H8.5C7.9,4.5,7.4,4.7,6.9,5
          C6.5,5.3,6.2,5.8,6,6.3L3.1,16.5C3,16.8,3,17.2,3,17.6c0.1,0.4,0.2,0.7,0.4,1c0.2,0.3,0.5,0.6,0.8,0.7c0.1,0.1,0.2,0.1,0.3,0.2v14.1
          c0,1.3,0.5,2.6,1.4,3.5c0.9,0.9,2.2,1.4,3.5,1.4h24.1c1.3,0,2.6-0.5,3.5-1.4s1.4-2.2,1.4-3.5V19.5c0.1,0,0.2-0.1,0.3-0.1
          c0.3-0.2,0.6-0.4,0.8-0.8c0.2-0.3,0.3-0.6,0.4-1C39.9,17.2,39.9,16.9,39.8,16.5z M34.1,7.5l2.7,9.3c-1.9,0.2-3.5,0.1-4.5-0.2
          c-0.6-0.2-1.5-0.6-2.5-1.2l-0.7-7.9H34.1z M16.8,7.5h9.3l0.7,8c-0.9,0.5-1.8,0.9-2.3,1.1c-2.3,0.7-3.7,0.7-6,0
          c-0.6-0.2-1.4-0.6-2.3-1.1l0.2-2.7L16.8,7.5z M8.8,7.5h5l-0.7,7.9c-1,0.6-1.9,1-2.5,1.2c-1,0.3-2.6,0.4-4.5,0.2L8.8,7.5z M24.8,35.5
          h-7.3v-8.8c0-0.5,0.2-1,0.6-1.4s0.9-0.6,1.4-0.6h3.4c0.5,0,1,0.2,1.4,0.6s0.6,0.9,0.6,1.4v8.8H24.8z M34.9,34.9
          c-0.4,0.4-0.9,0.6-1.4,0.6h-5.7v-8.8c0-1.3-0.5-2.6-1.4-3.5s-2.2-1.4-3.5-1.4h-3.4c-1.3,0-2.6,0.5-3.5,1.4s-1.4,2.2-1.4,3.5v8.8H9.4
          c-0.5,0-1-0.2-1.4-0.6c-0.3-0.3-0.5-0.8-0.5-1.3V19.9c0.2,0,0.5,0,0.7,0c1.2,0,2.4-0.1,3.4-0.4s2.2-1,3-1.4c0.9,0.5,2,1.1,3,1.4
          c1.4,0.4,2.7,0.7,3.9,0.7c1.2,0,2.5-0.2,3.9-0.7c1-0.3,2.1-0.9,3-1.4c0.9,0.5,2,1.1,3,1.4c1,0.3,2.2,0.4,3.4,0.4c0.2,0,0.5,0,0.7,0
          v13.7C35.5,34.1,35.2,34.6,34.9,34.9z"/>
        );

      case 'IcoPlatformIntegrations':
        return (
          <path d="M39.8,35.4H3.2c-2.4,0-3.3-1.8-3.3-3V31h3.6V10.6C3.6,9.7,4,9,4.6,8.4c0.6-0.5,1.4-0.8,2.2-0.8h29.2c0.9,0,1.6,0.3,2.2,0.8
          c0.7,0.6,1.1,1.3,1.1,2.2v20.3h3.6v1.4C43,33.6,42.2,35.4,39.8,35.4z M6.5,29.4h30.1V10.6c-0.1-0.1-0.2-0.1-0.4-0.1H6.8
          c-0.2,0-0.3,0.1-0.4,0.1L6.5,29.4z"/>
        );
      case 'IcoPaymentsSolutions':
        return (
          <path d="M2,31.8V21.5h39v10.3c0,0.7-0.3,1.4-0.8,1.9s-1.2,0.8-1.9,0.8H4.7c-0.3,0-0.7-0.1-1-0.2C3.3,34.2,3,34,2.8,33.7l0,0
          c-0.3-0.2-0.5-0.5-0.6-0.9C2.1,32.5,2,32.2,2,31.8L2,31.8L2,31.8L2,31.8z M12.8,28v2.2h6.5V28H12.8z M6.3,28v2.2h4.3V28H6.3z
            M38.3,8.5c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9V15H2v-3.8c0-0.3,0.1-0.7,0.2-1c0.1-0.3,0.3-0.6,0.6-0.9l0,0C3,9,3.3,8.8,3.7,8.7
          c0.3-0.1,0.7-0.2,1-0.2l0,0l0,0H38.3z"/>
        );
      case 'IcoArtificialIntelligence':
        return (
          <path d="M12,4c1.6-1.3,3.7-2,5.9-2c1.3,0,2.3,0.4,3.1,1.1c0.2,0.2,0.4,0.4,0.6,0.6c0.2-0.2,0.4-0.4,0.6-0.6C22.8,2.4,23.9,2,25.1,2
            c2.1,0,4.2,0.7,5.9,2c1.3,1,2.3,2.3,2.6,4c0.8,0.1,1.5,0.6,2.1,1.1c0.9,0.9,1.6,2.2,2,3.5s0.6,2.8,0.6,4.3c0,0.7-0.1,1.5-0.3,2.2
            l0.1,0.1c0.7,0.3,1.3,0.9,1.7,1.6c0.8,1.3,1.1,3.3,1.1,5.7c0,2.8-1.1,4.7-2.5,5.9c-0.7,0.6-1.6,1.1-2.5,1.3c-0.3,1.5-1,3-2,4.2
            c-1.4,1.8-3.6,3.3-6.6,3.3c-2.4,0-4.2-1.3-5.4-2.6c-0.2-0.2-0.4-0.5-0.6-0.7c-0.2,0.2-0.4,0.5-0.6,0.7c-1.2,1.3-3.1,2.6-5.4,2.6
            c-2.9,0-5.1-1.5-6.6-3.3c-1-1.2-1.7-2.6-2-4.2c-0.9-0.3-1.8-0.7-2.5-1.3C3.1,31,2,29.1,2,26.3c0-2.5,0.3-4.4,1.1-5.7
            C3.6,19.9,4.2,19.3,5,19c-0.2-0.7-0.3-1.5-0.3-2.2c-0.1-1.4,0.1-2.9,0.6-4.3c0.4-1.3,1.1-2.6,2-3.5c0.6-0.6,1.3-1,2.1-1.1
            C9.8,6.3,10.8,4.9,12,4z M13.8,6.3c-1.1,0.8-1.7,1.9-1.7,3.2c0,0.2-0.1,0.5-0.2,0.7c0,0.1-0.2,0.3-0.4,0.5c-0.2,0.1-0.4,0.2-0.6,0.2
            c-0.2,0-0.5,0-0.7-0.1c-0.3-0.1-0.6-0.1-1,0.3c-0.4,0.5-0.9,1.3-1.2,2.3s-0.5,2.1-0.4,3.2c0,1.1,0.3,2,0.7,2.6
            c0.1,0.1,0.1,0.2,0.2,0.3h2.1c1.4,0,2.8,0.5,3.8,1.5s1.7,2.3,1.8,3.7c0.8,0.3,1.5,1,2,1.7c0.4,0.8,0.6,1.7,0.4,2.6s-0.6,1.7-1.3,2.3
            c-0.7,0.6-1.6,0.9-2.5,0.9s-1.8-0.3-2.5-0.9c-0.8-0.5-1.3-1.3-1.5-2.2s0-1.8,0.4-2.6c0.4-0.8,1.1-1.4,2-1.8
            c-0.1-0.6-0.4-1.2-0.9-1.6s-1.1-0.6-1.7-0.6H5.4c-0.3,0.7-0.5,1.9-0.5,3.8c0,2,0.7,3.1,1.4,3.7c0.8,0.7,1.7,0.8,1.9,0.8
            c0.4,0,0.8,0.2,1,0.4c0.3,0.3,0.4,0.6,0.4,1c0,0.8,0.5,2.3,1.5,3.6c1,1.2,2.4,2.2,4.3,2.2c1.2,0,2.4-0.7,3.3-1.7
            c0.4-0.5,0.8-0.9,1-1.3c0.1-0.1,0.2-0.3,0.2-0.5l0,0V16.1h-1.7c-0.3,0.8-1,1.5-1.8,2c-0.8,0.4-1.7,0.6-2.6,0.4s-1.7-0.6-2.3-1.3
            c-0.6-0.7-0.9-1.6-0.9-2.5s0.3-1.8,0.9-2.5c0.7-0.7,1.5-1.2,2.4-1.4s1.8,0,2.6,0.4c0.8,0.4,1.4,1.1,1.8,2H20v-5V8.1
            c0-0.6-0.2-1.2-0.4-1.8c-0.2-0.5-0.4-0.8-0.7-1.1c-0.2-0.2-0.6-0.4-1.1-0.4C16.4,4.9,14.9,5.4,13.8,6.3L13.8,6.3z M23,31.3v3.4l0,0
            c0,0.1,0.1,0.3,0.2,0.5c0.2,0.4,0.6,0.9,1,1.3c0.9,1,2.1,1.7,3.3,1.7c1.9,0,3.3-0.9,4.3-2.2s1.5-2.8,1.5-3.6c0-0.4,0.2-0.8,0.4-1
            c0.3-0.3,0.6-0.4,1-0.4c0.2,0,1.1-0.2,1.9-0.8c0.7-0.6,1.4-1.7,1.4-3.7c0-2.4-0.3-3.6-0.7-4.2c-0.1-0.2-0.3-0.4-0.5-0.5
            s-0.5-0.2-1-0.2c-0.3,0-0.5-0.1-0.8-0.2c-0.2-0.1-0.4-0.3-0.5-0.6c-0.1-0.2-0.2-0.5-0.2-0.8s0.1-0.5,0.2-0.7
            c0.4-0.6,0.7-1.5,0.7-2.6s-0.1-2.2-0.4-3.2c-0.3-1-0.8-1.8-1.2-2.2s-0.7-0.4-1-0.3c-0.2,0.1-0.5,0.1-0.7,0.1s-0.4-0.1-0.6-0.3
            c-0.2-0.1-0.3-0.3-0.4-0.5s-0.2-0.4-0.2-0.7c0-1.3-0.6-2.4-1.7-3.2s-2.6-1.3-4.1-1.3c-0.5,0-0.9,0.2-1.1,0.4
            c-0.3,0.3-0.5,0.7-0.7,1.1c0,0.4-0.1,1-0.1,1.7v20.1h1.2c0.7,0,1.4-0.3,1.9-0.8s0.8-1.2,0.8-1.9v-3.5c-0.8-0.3-1.5-1-2-1.8
            s-0.6-1.7-0.4-2.6s0.6-1.7,1.3-2.3c0.7-0.6,1.6-0.9,2.5-0.9s1.8,0.3,2.5,0.9s1.2,1.4,1.3,2.3c0.2,0.9,0,1.8-0.4,2.6s-1.1,1.4-2,1.8
            v3.5c0,1.5-0.6,2.9-1.6,3.9s-2.5,1.6-3.9,1.6L23,31.3L23,31.3z M14.7,13.7c-0.3,0-0.5,0.1-0.7,0.3s-0.3,0.4-0.3,0.7s0.1,0.5,0.3,0.7
            s0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3s0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7S14.9,13.7,14.7,13.7z M13.7,28.3c0,0.3,0.1,0.5,0.3,0.7
            s0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3-0.1-0.5-0.3-0.7s-0.4-0.3-0.7-0.3s-0.5,0.1-0.7,0.3
            C13.8,27.8,13.7,28.1,13.7,28.3z M27.3,18.6c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.4,0.3,0.7,0.3c0.3,0,0.5-0.1,0.7-0.3
            c0.2-0.2,0.3-0.4,0.3-0.7s-0.1-0.5-0.3-0.7c-0.2-0.2-0.4-0.3-0.7-0.3c-0.3,0-0.5,0.1-0.7,0.3C27.4,18.1,27.3,18.3,27.3,18.6z"/>
        );
      case 'IcoSoftwareProductDesign':
        return (
          <>
          <path d="M41,35.9H2c-0.8,0-1.5-0.7-1.5-1.5V8.5C0.5,7.7,1.2,7,2,7h39c0.8,0,1.5,0.7,1.5,1.5v25.9C42.5,35.2,41.8,35.9,41,35.9z
    M3.5,32.9h36V10h-36V32.9z"/>
  <path d="M36.5,30.6h-12c-0.8,0-1.5-0.7-1.5-1.5V13.8c0-0.8,0.7-1.5,1.5-1.5h12c0.8,0,1.5,0.7,1.5,1.5v15.3
    C38,30,37.3,30.6,36.5,30.6z M26,27.6h9V15.3h-9V27.6z"/>
  <path d="M18.5,21.8h-12c-0.8,0-1.5-0.7-1.5-1.5v-6.5c0-0.8,0.7-1.5,1.5-1.5h12c0.8,0,1.5,0.7,1.5,1.5v6.5
    C20,21.1,19.3,21.8,18.5,21.8z M8,18.8h9v-3.5H8V18.8z"/>
  <path d="M11,25.9H7.3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h3.8c0.8,0,1.5,0.7,1.5,1.5S11.8,25.9,11,25.9z"/>
  <path d="M17.7,29.4H7.3c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h10.5c0.8,0,1.5,0.7,1.5,1.5S18.6,29.4,17.7,29.4z"/>
          </>
        );
      case 'IcoGraphicDesign':
        return (
          <path d="M40.3,27.5H39v-0.1c0-6.3-3.4-11.9-8.4-14.9h6.3c0.4,0.7,1.2,1.2,2.2,1.2c1.4,0,2.5-1.1,2.5-2.5s-1.1-2.5-2.5-2.5
            c-0.9,0-1.7,0.5-2.2,1.2H25.3V8.7c0-0.7-0.6-1.2-1.2-1.2h-5c-0.7,0-1.2,0.6-1.2,1.2v1.2H6.2C5.7,9.3,4.9,8.7,4,8.7
            c-1.4,0-2.5,1.2-2.5,2.6s1.1,2.5,2.5,2.5c0.9,0,1.7-0.5,2.2-1.2h6.3c-5,3.1-8.4,8.6-8.4,14.9l0,0H2.7c-0.6,0-1.2,0.6-1.2,1.2v5
            c0,0.7,0.6,1.2,1.2,1.2h5c0.7,0,1.2-0.6,1.2-1.2v-5c0-0.7-0.6-1.2-1.2-1.2H6.5v-0.1c0-7,4.8-12.8,11.2-14.5v0.8
            c0,0.7,0.6,1.2,1.2,1.2h5c0.7,0,1.2-0.6,1.2-1.2v-0.8c6.6,1.7,11.4,7.6,11.4,14.5v0.1h-1.2c-0.7,0-1.2,0.6-1.2,1.2v5
            c0,0.7,0.6,1.2,1.2,1.2h5c0.7,0,1.2-0.6,1.2-1.2v-5C41.5,28.1,40.9,27.5,40.3,27.5z M6.5,32.5H4V30h2.5V32.5z M22.7,12.5h-2.5V10
            h2.5V12.5z M39,32.5h-2.5V30H39V32.5z"/>
        );
      case 'IcoMarketAnalysis':
        return (
          <path d="M37.7,11.3c-0.7-0.7-1.5-1-2.4-1h-5.3V8.8c0-1.1-0.2-2.2-0.6-3.2c-0.4-1-1-1.9-1.8-2.7c-0.8-0.8-1.7-1.4-2.7-1.8
            c-2-0.8-4.3-0.8-6.4,0c-1,0.4-1.9,1-2.7,1.8s-1.4,1.7-1.8,2.7c-0.4,1-0.6,2.1-0.6,3.2v1.4H8c-0.9,0-1.8,0.4-2.4,1
            c-0.7,0.7-1,1.5-1,2.4V39c0,0.9,0.4,1.8,1,2.4c0.7,0.7,1.5,1,2.4,1h27.3c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4V13.7
            C38.7,12.8,38.3,11.9,37.7,11.3z M16.3,8.8c0-0.7,0.1-1.4,0.4-2s0.7-1.2,1.2-1.7c0.5-0.5,1.1-0.9,1.7-1.2c1.3-0.5,2.8-0.5,4.1,0
            c0.6,0.3,1.2,0.7,1.7,1.2c0.5,0.5,0.9,1.1,1.2,1.7c0.3,0.6,0.4,1.3,0.4,2v1.4H16.3V8.8z M35.7,39c0,0.1,0,0.2-0.1,0.3
            c-0.1,0.1-0.2,0.1-0.3,0.1H8c-0.1,0-0.2,0-0.3-0.1c-0.1-0.1-0.1-0.2-0.1-0.3V13.7c0-0.1,0-0.2,0.1-0.3s0.2-0.1,0.3-0.1h5.3v2.4
            c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-2.4h10.7v2.4c0,0.8,0.7,1.5,1.5,1.5s1.5-0.7,1.5-1.5v-2.4h5.3c0.1,0,0.2,0,0.3,0.1
            c0.1,0.1,0.1,0.2,0.1,0.3V39z"/>
        );
      case 'IcoDigitalMarketing':
        return (
          <path d="M35.8,8c-0.4-0.3-0.8-0.4-1.3-0.4s-0.9,0-1.4,0.2l-14.2,5.6c-0.3,0.1-0.7,0.2-1.1,0.2h-9c-0.8,0-1.5,0.3-2,0.8
            S6,15.7,6,16.4v0.2H3v6.9h2.9v0.3c0,0.7,0.3,1.5,0.9,2c0.5,0.5,1.3,0.8,2,0.8l3.5,7.3c0.2,0.5,0.6,0.9,1.1,1.2
            c0.5,0.3,1,0.4,1.5,0.5h1.4c0.8,0,1.5-0.3,2-0.8s0.8-1.3,0.8-2V27l14,5.6c0.3,0.1,0.7,0.2,1.1,0.2c0.6,0,1.1-0.2,1.6-0.5
            c0.4-0.3,0.7-0.6,0.9-1s0.3-0.8,0.4-1.3V10.3c0-0.5-0.1-0.9-0.3-1.3C36.5,8.6,36.2,8.2,35.8,8z M16.2,16.4v7.4H8.8v-7.4H16.2z
            M16.2,32.6h-1.4l-2.8-6h4.3L16.2,32.6L16.2,32.6z M20,24.1c-0.3-0.1-0.6-0.3-0.9-0.3v-7.6c0.3-0.1,0.6-0.1,0.9-0.3l14.2-5.6v19.4
            L20,24.1z M37.1,17.2v5.7c0.8,0,1.5-0.3,2-0.8s0.8-1.3,0.8-2c0-0.8-0.3-1.5-0.8-2C38.6,17.5,37.9,17.2,37.1,17.2z"/>
        );
      default:
        return null;
    }
  };




const AdvanteIcons = ({ type, fill }) => {
  const iconStyles = {
    fill: fill, // Usar el valor de la variable fill
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 43" style={iconStyles}>
      {getIconPath(type)}
    </svg>
  );
};

export default AdvanteIcons;